<template>
  <div page="page">
    <div class="page__title">
     {{ getModuleConfig("my_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('my_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('my_page.content')" v-html="getModuleConfig('my_page.content')"></div>
    <coupon-filters
      v-if="showFilter"
      class="s-mb-3"
      :options="filterOptions"
      v-model="filter"
    ></coupon-filters>
    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <coupon-card
        v-else-if="!showLoading && coupons.length > 0"
        v-for="item in filteredCoupons[filter]"
        :key="item.id"
        :coupon="item"
        :buttonText="item.status == 'applied_and_not_yet' ? '尚未開始' : '查看'"
        @exchange="showCouponModal"
      >
      </coupon-card>
      <coupon-empty v-else />
      <Pagination v-model="page" :total-page="lastPage" />
    </div>
    <coupon-modal ref="couponModal" :coupon="selectedCoupon" @confirmed="refresh"/>
  </div>
</template>

<script>
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue"
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue"
import CouponModal from "@/components/Page/Liff/CouponList/CouponModal.vue"
import couponApi from '@/apis/liff/v2/coupon'
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { isAfter, addDays } from "date-fns";
import Pagination from '@/components/Page/Liff/Shared/Pagination'
import couponMixin from "@/mixins/liff/coupon";

export default {
  mixins: [couponMixin],
  components: { CouponFilters, CouponCard, CouponModal, CouponEmpty, Loading, Pagination },
  data() {
    return {
      showLoading: true,
      provider: null,
      filter: "all",
      coupons: [],
      selectedCoupon: {},
      page: +this.$route.query.page || 1,
      lastPage: 1,
    }
  },

  computed: {
    showFilter() {
      return this.provider == "waltily"
    },
    filterOptions() {
      return [
        {
          key: "all",
          text: `${"全部 (" + this.coupons.length + ")"}`,
        },
        {
          key: "expiring_soon",
          text: `${"即將到期 (" + this.filteredCoupons.expiring_soon.length + ")"}`,
        },
        {
          key: "notYet",
          text: `${"尚未開始 (" + this.filteredCoupons.notYet.length + ")"}`,
        },
      ]
    },
    filteredCoupons() {
      const today = new Date().toJSON().slice(0, 10)
      const comingDay = addDays(new Date(today), 7)

      return {
        all: this.coupons,
        expiring_soon: this.coupons.filter((c) => {
          if (c.status === "expiring_soon") {
            return true
          }
          // 因為我的優惠卷會顯示五分鐘內使用過的優惠卷，此時優惠卷的狀態會是 redeemed，非 expiring_soon
          // 所以要額外判斷 end_at 是否在今天到七天後之間
          return c.end_at && isAfter(comingDay, new Date(c.end_at))
        }),
        notYet: this.coupons.filter((c) => c.status === "applied_and_not_yet"),
      }
    },
  },
  watch: {
    page () {
      this.fetchCoupon()
    },
  },

  mounted() {
    this.fetchCoupon()
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true
      await this.doFetchCoupon()
      this.showLoading = false
    },
    async doFetchCoupon() {
      this.coupons = []
      try {
        const data = await couponApi.getCouponLists({
          page: this.page,
          status: 'my',
        });
        this.coupons = data.data
        this.provider = data.provider
        this.lastPage = data.lastPage
      } catch (error) {
        if (error.response.data.message) {
          this.$swal.fire({
            type: "error",
            title: "錯誤",
            text: error.response.data.message
          });
        }
      }

      this.coupons.forEach((coupon) => {
        coupon.is_enabled = true
      })
    },
    showCouponModal(coupon) {
      this.selectedCoupon = coupon
      this.$refs.couponModal.open()
    },
    refresh() {
      this.selectedCoupon = {}
      this.fetchCoupon()
    },
  },
}
</script>

<style lang="scss" scoped></style>
