<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("branch_picker_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('branch_picker_page.form_desc')"
    ></div>

    <div class="card pt-2 px-2">
      <div class="card__info">
        <div v-if="filter != null">
        <coupon-filters
            class="s-mb-3"
            :options="filterOptions"
            v-model="filter"
        ></coupon-filters>

        <div>
            <Loading v-if="showLoading" loading-message="載入中..." />
            <div v-else-if="!showLoading && filter == 'qrcode'" class="qrcode-panel">
            <QRCodeScanner @onScan="onScan" :qrbox="qrbox" />
            </div>
            <div v-else-if="!showLoading && filter == 'manual'">
            <input class="search__input m-auto w-100" v-model="barcode" @input="barcodeFormatter" />
            </div>
        </div>
        </div>

        <div>
        <Loading v-if="showLoading" loading-message="載入中..." />
        <div v-else-if="!showLoading">
            <div
            v-html="getModuleConfig('branch_picker_page.content')"
            class="p-4 rounded picker-content"
            ></div>
        </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('branch_picker_page.button_text') ||
        getModuleConfig('branch_picker_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton class="s-btn-bg-primary mb-2" @click="bindStore" :disabled="!barcode || showLoading">{{
        getModuleConfig("branch_picker_page.button_text")
      }}</SharedButton>

      <MemberButton
        :button-style='`s-btn-outline-primary`'
        :button-text="getModuleConfig('branch_picker_page.cancel_button_text')"
        :button-url="getModuleConfig('branch_picker_page.cancel_button_url')"
        :type="getModuleConfig('branch_picker_page.cancel_button_action')"
        :disabled="showLoading"
      ></MemberButton>

      <!-- <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("branch_picker_page.cancel_button_text")
      }}</SharedButton> -->
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import branchMixin from "@/mixins/liff/branch";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";
import QRCodeScanner from "@/components/QRCodeScanner";
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [branchMixin, abbottAndMixin],
  components: {
    Loading,
    SharedButton,
    CouponFilters,
    QRCodeScanner,
    MemberButton
  },
  data() {
    return {
      qrbox: {
        width: 220,
        height: 220,
      },
      showLoading: false,
      options: [{ text: "特選藥局", value: "特選藥局" }],
      proxy: null,
      filter: null,
      barcode: null,
      channel: null
    };
  },

  computed: {
    ...mapGetters({
      isBindStoreMember: "projAbbottAnd/isBindStore",
    }),
    filterOptions() {
      return [
        {
          key: "qrcode",
          text: "QR Code 掃描",
        },
        {
          key: "manual",
          text: "手動輸入",
        },
      ];
    },
  },
  mounted() {
    this.checkAndMember();

    this.filter = 'qrcode'
  },

  methods: {
    ...mapActions('projAbbottAnd', ['setThanksMessage', 'updateQuery']),
    async onScan(decodedText) {
      this.barcode = decodedText;
      this.filter = 'manual'
    },
    barcodeFormatter(event) {
      let value = event.target.value;
      this.barcode = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    },
    async bindStore() {
      if (!this.barcode) {
        alert("請輸入或掃描店家代碼");
        return;
      }
      try {
        this.showLoading = true;
        this.updateQuery({
          store_code: this.barcode,
          need_bind_store: true
        });
        this.$router.push({ name: "LiffProjAbbottAndSendCoupon" });
        this.showLoading = false;
      } catch (error) {
        this.setThanksMessage(error.response.data.message);
        this.$router.push({
          name: "LiffProjAbbottAndWelcome",
        });
        this.showLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}

.qrcode-panel {
  width: 70%;
  margin: auto;
}

.page {
  padding-bottom: 140px !important;
}

.picker-content {
    img {
        max-width: 100%;
        height: auto;
    }
}

</style>
