<template>
  <div
    id="modal-benefit-setting"
    title="新增權益"
  >
    <div class="text-center mt-3 mb-3 loading-spinner" v-if="isLoading">
      <b-spinner variant="secondary" small />
    </div>
    <div class="mt-3 mb-3">
      <validation-error-alert
        v-if="validationErrors"
        :errors="validationErrors"
      ></validation-error-alert>
      <!-- 名稱 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="*名稱"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.tab_name.$error ? false: null"
          v-model="localBenefitSetting.tab_name"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.tab_name.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 名稱 End -->
      <!-- 路徑 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="*路徑"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.path.$error ? false: null"
          v-model="localBenefitSetting.path"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.path.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 路徑 End -->
      <!-- 順序 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="*順序"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.order.$error ? false: null"
          v-model="localBenefitSetting.order"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.order.$invalid">
          <span v-if="!$v2.localBenefitSetting.order.$pending && $v2.localBenefitSetting.order.$errors.some(e => e.$validator === 'required')">
            此欄位為必填
          </span>
          <span v-if="!$v2.localBenefitSetting.order.$pending && $v2.localBenefitSetting.order.$errors.some(e => e.$validator === 'numeric')">
            此欄位必須是數字
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 順序 End -->
      <!-- 圖片 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="圖片"
      >
        <button class="btn btn-link px-2 text-dark align-self-start upload-button">
          <b-form-file @change="upload($event)" accept="image/*"  placeholder="尚未選擇檔案" browse-text="瀏覽"/>
          <a
            v-if="localBenefitSetting.tab_image"
            @click="handleDelete"
            class="btn-sm btn-outline-danger"
          >
            <i class="fa fa-trash-o"></i>
          </a>
          <a :href="localBenefitSetting.tab_image" target="_blank" style="word-break: break-all;"
          ><small>
            {{ displayText('upload_file.field_upload_file_current_path', '目前路徑') }}：{{ localBenefitSetting.tab_image || displayText('upload_file.field_upload_file_not_uploaded', '未上傳') }}
          </small></a
          >
        </button>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.tab_image.$error">
          {{ displayText('upload_file.validation_required', '此欄位為必填') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 圖片 End -->
      <!-- 主標題 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="主標題"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.form_title.$error ? false: null"
          v-model="localBenefitSetting.form_title"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.form_title.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 主標題 End -->
      <!-- 副標題 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="副標題"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.form_desc.$error ? false: null"
          v-model="localBenefitSetting.form_desc"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.form_desc.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 副標題 End -->
      <!-- 說明內容 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="說明內容"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.content.$error ? false: null"
          v-model="localBenefitSetting.content"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.content.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 說明內容 End -->
      <!-- 立即使用按鈕文字 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="立即使用按鈕文字"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.use_now_button_text.$error ? false: null"
          v-model="localBenefitSetting.use_now_button_text"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.use_now_button_text.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 立即使用按鈕文字 End -->
      <!-- 預設按鈕文字 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="預設按鈕文字"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.button_text.$error ? false: null"
          v-model="localBenefitSetting.button_text"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.button_text.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 預設按鈕文字 End -->
      <!-- 預設按鈕動作 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="預設按鈕動作"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.button_action.$error ? false: null"
          v-model="localBenefitSetting.button_action"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.button_action.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 預設按鈕動作 End -->
      <!-- 預設按鈕網址 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="預設按鈕網址"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.button_url.$error ? false: null"
          v-model="localBenefitSetting.button_url"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.button_url.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 預設按鈕網址 End -->
      <!-- 延伸按鈕文字 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="延伸按鈕文字"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.tab_button_text.$error ? false: null"
          v-model="localBenefitSetting.tab_button_text"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.tab_button_text.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 延伸按鈕文字 End -->
      <!-- 延伸按鈕動作 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="延伸按鈕動作"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.tab_button_action.$error ? false: null"
          v-model="localBenefitSetting.tab_button_action"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.tab_button_action.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 延伸按鈕動作 End -->
      <!-- 延伸按鈕網址 Start -->
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="延伸按鈕網址"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          type="text"
          :state="$v2.localBenefitSetting.tab_button_url.$error ? false: null"
          v-model="localBenefitSetting.tab_button_url"
        ></b-form-input>
        <b-form-invalid-feedback :state="!$v2.localBenefitSetting.tab_button_url.$error">
          此欄位為必填
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- 延伸按鈕網址 End -->
      <!-- 選擇優惠券 Start -->
      <div class="d-flex align-items-center justify-content-between mb-3">
        <!-- 選擇優惠券按鈕區 start -->
        <b-button
          class="flex-shrink-0 mr-2"
          variant="inverse-primary"
          v-b-modal.modal-search-single-coupon
        ><i class="fa fa-plus"></i>選擇優惠券</b-button>
        <!-- 選擇優惠券按鈕區 end -->
      </div>
      <!-- 優惠券清單 Start -->
      <b-table v-show="coupon && Object.keys(coupon).length > 0" striped hover responsive :items="[coupon]" :fields="couponFields">
        <template #cell(name)="data">
          <router-link :to="{ name: 'RedeemCodeBundleEdit', params: { redeemCodeBundleId: data.item.id } }">
            <div>{{ deepGet(data.item, 'name') }}</div>
          </router-link>
        </template>
        <template #cell(img_url)="data">
          <b-img-lazy
            v-if="deepGet(data.item, 'img_url')"
            class="thumb-image"
            :src="deepGet(data.item, 'img_url')"
            alt="圖片"
            width="50"
            height="50"
          />
        </template>
        <template #cell(quantity)="data">
          <div>{{ deepGet(data.item, 'quantity') }}</div>
        </template>
        <template #cell(dateline_type)="data">
          <div>
            <!-- 根據 dateline_type 的值顯示不同文字 -->
            <span v-if="deepGet(data.item, 'dateline_type') === 0">自定日期區間</span>
            <span v-else-if="deepGet(data.item, 'dateline_type') === 1">依日期區間推算</span>
            <span v-else-if="deepGet(data.item, 'dateline_type') === 2">與優惠券的領取期限相同</span>
            <span v-else-if="deepGet(data.item, 'dateline_type') === 3">與此權益卡期限相同</span>
            <span v-else-if="deepGet(data.item, 'dateline_type') === 4">與會員會籍期限相同</span>
            <span v-else-if="deepGet(data.item, 'dateline_type') === 5">每月到期(當月1日起)</span>
            <span v-else-if="deepGet(data.item, 'dateline_type') === 6">每月到期(自會籍起始日起)</span>
            <span v-else>其他</span>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div>{{ formatDate(deepGet(data.item, 'created_at')) }}</div>
        </template>
        <template #cell(actions)="data">
          <b-button
            class="mr-1"
            variant="inverse-info"
            v-b-modal.modal-edit-quantity
            @click="editCoupon(data.item)"
          >
            <span class="mdi mdi-settings"></span> 設定
          </b-button>
          <b-button
            class="mr-1"
            variant="inverse-danger"
            @click="deleteCoupon"
          >
            <span class="mdi mdi-delete"></span> 移除
          </b-button>
        </template>
      </b-table>
      <!-- 優惠券清單 End -->
      <SearchCoupon :selectedCoupon="coupon" @submit="handleCouponSubmit"/>
      <!-- 選擇優惠券 End-->

      <b-modal
          id="modal-edit-quantity"
          title="發放設定"
          size="md"
          centered
          @ok="handleEdit"
      >
        <form ref="form" @submit.stop.prevent="handleEdit">

          <div>
            有效期間：
          </div>
          <b-form-radio-group v-model="selectedOption" stacked name="dateline_type">
            <div class="custom-radio-wrapper">
              <b-form-radio :value="0" class="custom-radio">
                自定日期區間
              </b-form-radio>
              <DateSearchPicker
                  class="ml-md-4 mr-md-2"
                  v-model="dateBetween"
                  startedPlaceholder="開始時間"
                  endedPlaceholder="結束時間"
                  :disabled="selectedOption !== 0"
                  @input="handleDateChange($event)"
              />
            </div>

            <b-form-radio :value="1" class="custom-radio">
              依日期區間推算
              <div>領取後幾日可以使用(0為當日)：</div>
              <b-form-group>
                <b-form-input
                    id="start_days_after_take"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="start_days_after_take"
                    :disabled="selectedOption !== 1"
                    placeholder="請輸入數量"
                ></b-form-input>
              </b-form-group>
              <div>領取後幾日內有效(0為當日需使用完畢)：</div>
              <b-form-group>
                <b-form-input
                    id="end_days_after_take"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="end_days_after_take"
                    :disabled="selectedOption !== 1"
                    placeholder="請輸入數量"
                ></b-form-input>
              </b-form-group>
            </b-form-radio>

            <b-form-radio :value="2" class="custom-radio">與優惠券的領取期限相同</b-form-radio>
            <b-form-radio :value="3" class="custom-radio">與此權益卡期限相同</b-form-radio>
            <b-form-radio :value="4" class="custom-radio">與會員會籍期限相同</b-form-radio>
            <b-form-radio :value="5" class="custom-radio">每月到期(當月1日起)</b-form-radio>
            <b-form-radio :value="6" class="custom-radio">每月到期(自會籍起始日起)</b-form-radio>
          </b-form-radio-group>

          <div>
            發放數量：
          </div>
          <b-form-group>
            <div style="display: flex; align-items: center; justify-content: center;padding-top:10px;">
              <div class="mr-2 mb-2" v-show="selectedOption == 5 || selectedOption == 6">每月</div>
              <b-form-input
                  id="name"
                  class="col-8 mb-2 mr-sm-1"
                  v-model="coupon.quantity"
                  placeholder="請輸入數量"
              ></b-form-input>
              <div class="col-2 mb-2">張</div>
            </div>
          </b-form-group>
        </form>

        <template #modal-footer="{ ok }">
          <b-button size="sm" variant="danger" @click="closeEditQuantity()">
            取消
          </b-button>
          <b-button size="sm" variant="success" @click="ok()">
            確認送出
          </b-button>
        </template>
      </b-modal>
    </div>

    <template>
      <div class="w-100">
        <div class="d-flex justify-content-center">
          <b-button
            variant="danger"
            size="sm"
            class="mr-2 ml-5"
            @click="closeModal"
          >
            取消
          </b-button>
          <b-button
            variant="success"
            size="sm"
            @click="submitBenefitSetting"
          >
             儲存
          </b-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { format } from "date-fns";
import useVuelidate from "@vuelidate/core";
import {numeric, required} from "@vuelidate/validators";
import {displayText} from "@/utils/dashboardUtils";
import fileApi from "@/apis/file";
import SearchCoupon from "@/pages/Dashboard/Share/SearchCoupon.vue";
import deepGet from "lodash/get";
import {cloneDeep} from "lodash";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker.vue";
import moment from "moment/moment";

export default {
  components: {DateSearchPicker, SearchCoupon},
  setup: () => ({ $v2: useVuelidate() }),
  validations() {
    return {
      localBenefitSetting: {
        tab_name: { required },
        path: { required },
        order: { required, numeric },
        tab_image: {},
        form_title: {},
        form_desc: {},
        content: {},
        use_now_button_text: {},
        button_text: {},
        button_action: {},
        button_url: {},
        tab_button_text: {},
        tab_button_action: {},
        tab_button_url: {},
      }
    };
  },
  data() {
    return {
      isLoading: false,
      tempBenefitSetting: {},
      localBenefitSetting: {},
      validationErrors: null,
      coupon: {},
      tempCouponQuantity: 1,
      couponFields: [
        { key: "img_url"   , label: "優惠券圖片" },
        { key: "name"      , label: "優惠券名稱" },
        { key: "quantity"  , label: "數量" },
        { key: "dateline_type"  , label: "發放類型" },
        { key: "created_at", label: "建立日期" },
        { key: "actions"   , label: "管理" },
      ],
      selectedBenefitSetting: {
        type: Object,
        default: () => ({
          tab_name: "",
          path: "",
          order: 1,
          tab_image: "",
          form_title: "",
          form_desc: "",
          content: "",
          use_now_button_text: "",
          tab_button_text: "",
          button_text: "",
          button_action: "",
          button_url: "",
          redeem_code_bundles: [{
            hashid: "",
            quantity: 1
          }]
        })
      },
      selectedCoupon: {
        type: Object,
      },
      selectedOption: 0, // 預設沒有選擇
      radioOptions: [
        { value: 0, text: '自定日期區間' },
        { value: 1, text: '依日期區間推算' },
        { value: 2, text: '與優惠券的領取日期相同' },
        { value: 3, text: '與此權益卡期限相同' },
        { value: 4, text: '與會員會籍期限相同' },
        { value: 5, text: '每月到期(當月1日起）' },
        { value: 6, text: '每月到期(自會籍起始日起)' }
      ],
      dateBetween: {
        start_at: null,
        end_at: null,
      },
      start_days_after_take: 0,
      end_days_after_take: 0
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('zh-TW', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }).format(date).replace(/\//g, '-');
    },
    deepGet,
    displayText,
    format,
    initialize(selectedBenefitSetting, selectedCoupon) {
      this.tempBenefitSetting = selectedBenefitSetting; // 保存取消時的還原基準
      this.localBenefitSetting = cloneDeep(selectedBenefitSetting) // 初始化本地副本
      this.coupon = selectedCoupon ?? {};
      this.$v2.$reset(); // 重置驗證狀態，避免初始化時觸發驗證
    },

    async upload(e) {
      try {
        if (e.target.files[0].size > this.maxUploadSize * 1024 * 1024) {
          this.$swal.fire({
            title: displayText('upload_file.api_upload_file_failed_response_text', '上傳檔案失敗'),
            type: "error",
            text: `檔案大小不可超過${this.maxUploadSize}MB`,
          });
          return;
        }
        this.showLoading = true;
        let { data } = await fileApi.upload(e.target.files[0], null, true);
        this.localBenefitSetting.tab_image = data.data.file.url;
      } catch (error) {
        this.$swal.fire({
          title: displayText('upload_file.api_upload_file_failed_response_text', '上傳檔案失敗'),
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    handleDelete() {
      this.localBenefitSetting.tab_image = null
    },
    async submitBenefitSetting() {
      const result = await this.$v2.$validate();
      if (!result) return false;
      this.$emit("submit", this.localBenefitSetting, this.coupon);
    },
    closeModal() {
      this.$emit("close");
      if (Object.keys(this.tempBenefitSetting) > 0) {
        this.localBenefitSetting = { ...this.tempBenefitSetting }; // 還原資料
      }
      this.coupon.quantity = this.tempCouponQuantity;
    },
    handleCouponSubmit(selectedCoupon) {
      this.localBenefitSetting.redeem_code_bundles = [{
        hashid: selectedCoupon.id,
        quantity: 1,
        dateline_type: 2,
      }]

      selectedCoupon = {
        ...selectedCoupon,
        quantity: 1,
        dateline_type: 2,
      }
      this.coupon = selectedCoupon;
    },
    editCoupon(coupon) {
      this.tempCouponQuantity   = coupon.quantity
      this.selectedOption       = coupon.dateline_type;
      if (coupon.dateline_type === 0) {
        this.dateBetween.start_at = coupon.redeem_code_start_at;
        this.dateBetween.end_at   = coupon.redeem_code_end_at;
      } else {
        this.dateBetween.start_at = null;
        this.dateBetween.end_at   = null;
      }
      if (coupon.dateline_type === 1) {
        this.start_days_after_take = coupon.start_days_after_take;
        this.end_days_after_take   = coupon.end_days_after_take;
      } else {
        this.start_days_after_take = 0;
        this.end_days_after_take   = 0;
      }
    },
    deleteCoupon() {
      this.coupon = {};
      this.$delete(this.localBenefitSetting, 'redeem_code_bundles');
    },
    handleEdit(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.selectedOption === 0) { // 選擇0,要選擇開始結束日期
        if (this.dateBetween.start_at == null || this.dateBetween.end_at == null) {
          this.$swal.fire({
            title: "開始日期與結束日期不可為空",
            type: "error",
          });
          return;
        }

        // 確保結束日期不小於開始日期
        if (new Date(this.dateBetween.end_at) < new Date(this.dateBetween.start_at)) {
          this.$swal.fire({
            title: "結束日期不可早於開始日期",
            type: "error",
          });
          return;
        }

        this.coupon.redeem_code_start_at = this.dateBetween.start_at;
        this.coupon.redeem_code_end_at = this.dateBetween.end_at;

      } else if (this.selectedOption === 1) { // 選擇1,要輸入區間
        if (this.start_days_after_take < 0) {
          this.$swal.fire({
            title: "領取後幾日可以使用不可小於0",
            type: "error",
          });
          return;
        } else if(this.end_days_after_take < 0) {
          this.$swal.fire({
            title: "領取後幾日內有效不可小於0",
            type: "error",
          });
          return;
        }

        this.coupon.start_days_after_take = this.start_days_after_take;
        this.coupon.end_days_after_take = this.end_days_after_take;
      }

      if(!this.coupon.quantity) {
        this.$swal.fire({
          title: "請輸入數量",
          type: "error",
        });
        return;
      } else if(isNaN(this.coupon.quantity)) {
        this.$swal.fire({
          title: "請輸入數字",
          type: "error",
        });
        this.coupon.quantity = this.tempCouponQuantity
        return;
      }

      if (Array.isArray(this.localBenefitSetting.redeem_code_bundles) && this.localBenefitSetting.redeem_code_bundles.length > 0) {
        const matchingRedeemCode = this.localBenefitSetting.redeem_code_bundles.find(
          (bundle) => bundle.hashid === this.coupon.id
        );

        // 最終輸出的coupon會夾帶哪些欄位
        if (matchingRedeemCode) {
          matchingRedeemCode.quantity = this.coupon.quantity;
          matchingRedeemCode.dateline_type = this.selectedOption;

          if (this.selectedOption === 0) {
            matchingRedeemCode.redeem_code_start_at = this.dateBetween.start_at;
            matchingRedeemCode.redeem_code_end_at = this.dateBetween.end_at;
          } else {
            delete matchingRedeemCode.redeem_code_start_at;
            delete matchingRedeemCode.redeem_code_end_at;
          }
          if (this.selectedOption === 1) {
            matchingRedeemCode.start_days_after_take = this.start_days_after_take;
            matchingRedeemCode.end_days_after_take   = this.end_days_after_take;
          } else {
            delete matchingRedeemCode.start_days_after_take;
            delete matchingRedeemCode.end_days_after_take;
          }
        }
      }

      this.coupon.dateline_type = this.selectedOption;

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-quantity");
      });
    },
    closeEditQuantity() {
      this.coupon.quantity = this.tempCouponQuantity
      this.$bvModal.hide("modal-edit-quantity");
    },
    handleDateChange(between) {
      const startAt = (between.start_at) ? moment(between.start_at).format("YYYY-MM-DD 00:00:00") : null;
      const endAt = (between.end_at) ? moment(between.end_at).format("YYYY-MM-DD 23:59:59") : null;

      this.dateBetween.start_at = startAt;
      this.dateBetween.end_at = endAt;
    },
  }
};
</script>

<style lang="scss">
  .table-responsive {
    min-height: auto;
  }

  .custom-radio {
    z-index: auto;
    margin-bottom: 15px;
  }
</style>
