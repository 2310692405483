<template>
  <div class="shop-selection">
    <div v-if="Object.keys(candidateBranchModels).length == 0" class="mb-2">
      <SharedSelect
        class="mt-2"
        :value="null"
        :placeholder="isFetchingData ? placeholder : '查無資料'"
      />
    </div>
    <template>
      <!-- branch select -->
      <div v-for="(level) in Object.keys(candidateBranchModels)" :key="level" class="mb-2">
        <SharedSelect
          class="mt-2"
          v-model="selectedBranchIds[level]"
          :options="formatBranchModelsToOptions(candidateBranchModels[level])"
          :placeholder="placeholder"
          @input="handleAnyBranchSelected(selectedBranchIds[level], level)"
        />
      </div>
    </template>
  </div>
</template>

<script>
// NOTE: branch -> 分區或分店, area -> 分區, shop -> 分店
import branchApi from "@/apis/liff/v2/branch"
import branchApiAdmin from "@/apis/branch"
import SharedSelect from "@/components/Page/Liff/Shared/Select"
import branchMixin from "@/mixins/Dashboard/branches"
import _ from "lodash"
import { mapState } from 'vuex'

export default {
  props: {
    value: { // NOTE: branch id of a shop
      type: String,
      required: false,
    },
    branchProvider: { // example: "waltily.branch", "waltily.branch:N004", "waltily.branch:N004:true"
      type: String,
      required: true,
    },
    showBranchCode: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "請選擇區域/分店",
    },
    apiSource: {
      type: String,
      default: 'liff',
    },
  },
  mixins: [branchMixin],
  components: {
    SharedSelect,
  },
  data() {
    return {
      selectedBranchIds: {},
      selectedShopId: null,
      candidateBranchModels: {}, // NOTE: key -> branch level(does not start with 0), value -> array of branch models
      isFetchingData: false,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    deepestCandidateLevel() {
      return Math.max(...Object.keys(this.candidateBranchModels))
    },
  },
  watch: {
    value(shopId) {
      this.selectedShopId = shopId

      if (!!shopId && this.selectedShopId === null) {
        this.loadBranchOptionsBySelectedShopId(shopId)
      }
    },
  },
  mounted() {
    this.selectedShopId = this.value
    if (false === _.isEmpty(this.selectedShopId)) {
      this.loadBranchOptionsBySelectedShopId(this.selectedShopId)
    }
  },
  methods: {
    async handleAnyBranchSelected(branchId, currentSelectionLevel) {
      const selectedBranch = this.candidateBranchModels[currentSelectionLevel].find(branch => branch.id == branchId)

      if (currentSelectionLevel !== this.deepestCandidateLevel) {
        this.cleanDeeperLevelOptions(currentSelectionLevel)
      }

      if (branchId === null) {
        this.$emit('input', null)
        this.$emit('selected', {
          type: 'placeholder',
          id: null,
          model: null,
        })
        this.$emit('selectedNull')
      } else if (this.isShop(selectedBranch)) {
        this.selectedShopId = branchId
        this.$emit('input', branchId)
        this.$emit('selected', {
          type: 'shop',
          id: branchId,
          model: selectedBranch,
        })
        this.$emit('setBranchModel', selectedBranch)
        console.log('selected shop', branchId)
        this.$emit('selectedShop', branchId)
      } else if (this.isArea(selectedBranch)) {
        this.$emit('input', null)
        this.$emit('selected', {
          type: 'area',
          id: branchId,
          model: selectedBranch,
        })
        console.log('selected area', branchId)
        this.$emit('selectedArea', branchId)

        this.isFetchingData = true
        this.$emit('loading')
        await this.fetchBranchesAndPrepareCandidates({ type: 'level', branch_id: branchId })
        this.isFetchingData = false
        this.$emit('loaded')

        if (this.candidateBranchModels[currentSelectionLevel].length > 0) {
          this.selectedBranchIds[currentSelectionLevel+1] = null
        }
      }
    },
    async fetchBranches(params) {
      try {
        if (this.apiSource === 'admin') {
          return _.get(await branchApiAdmin.getBranchOptions(this.organization, params), 'data.data', [])
        } else {
          return _.get(await branchApi.getBranches(params), 'data.data', [])
        }
      } catch (error) {
        console.error('[ShopSelection] Failed to fetch branches.')
        console.error(error)
      }
    },
    async fetchBranchesAndPrepareCandidates(params) {
      const childrenBranchModels = await this.fetchBranches(params)

      if (childrenBranchModels.length > 0) {
        const level = childrenBranchModels[0].level
        this.$set(this.candidateBranchModels, level, childrenBranchModels)
      }
    },
    cleanDeeperLevelOptions(maximumPreservableLevel) {
      this.candidateBranchModels = Object.fromEntries(
        Object.entries(this.candidateBranchModels)
          .filter(([branchLevel, branchModel]) => branchLevel <= maximumPreservableLevel) // eslint-disable-line
      )
      this.selectedBranchIds = Object.fromEntries(
        Object.entries(this.selectedBranchIds)
          .filter(([branchLevel, selectedBranchId]) => branchLevel <= maximumPreservableLevel) // eslint-disable-line
      )
    },
    isArea(branchModel) {
      return _.get(branchModel, 'branch_type') === 'area'
    },
    isShop(branchModel) {
      return [null, 'shop'].includes(_.get(branchModel, 'branch_type'))
    },
    formatBranchModelsToOptions(branchModels) {
      if (!branchModels) {
        return []
      }
      return this.sortBranch(branchModels, branchModels[0]['org_id'], this.showBranchCode)
    },
    async loadBranchOptionsBySelectedShopId(shopId) {
      this.isFetchingData = true
      this.$emit('loading')

      const prepareParentCandidates = (allBranchModels, child) => {
        const leafParent = allBranchModels.find(branchModel => branchModel.id === child.parent_id)
        if (leafParent) {
          this.$set(this.selectedBranchIds, leafParent.level, leafParent.id)
          this.$set(this.candidateBranchModels, child.level, allBranchModels.filter(branchModel => branchModel.parent_id === child.parent_id))
        }
      }
      const allLevelCandidatesArePrepared = () => {
        const destIndex = parseInt(_.min(Object.keys(this.candidateBranchModels))) + 1
        return (_.max(Object.keys(this.selectedBranchIds)) !== _.min(Object.keys(this.selectedBranchIds))) &&
          _.get(this.candidateBranchModels, `[${destIndex}].length`, 0) > 0
      }

      // prepare top-level branch model candidates
      await this.$_fetchBranchesByProvider()

      // prepare all other level branch model candidates
      const allBranchModels = await this.fetchBranches({ type: 'all' })
      let selectedLeafBranch = allBranchModels.find(branchModel => branchModel.id === shopId)
      if (selectedLeafBranch) {
        this.$set(this.selectedBranchIds, selectedLeafBranch.level, selectedLeafBranch.id)
        do {
          prepareParentCandidates(allBranchModels, selectedLeafBranch)
          selectedLeafBranch = allBranchModels.find(branchModel => branchModel.id === selectedLeafBranch.parent_id)
        } while (false === allLevelCandidatesArePrepared() && selectedLeafBranch !== undefined)
      }

      this.isFetchingData = false
      this.$emit('loaded')
    },
    async $_fetchBranchesByProvider() {
      if (!this.branchProvider) {
        throw new Error('[ShopSelection] Branch provider is not set.')
      }

      let providerSetting = this.branchProvider.split(':')
      switch (true) {
        case providerSetting[1] === 'all':
          // 全部分店
          return await this.fetchBranchesAndPrepareCandidates({ type: 'all' })
        case providerSetting[1] === 'level':
        case providerSetting[1] === undefined:
          // 階級獲取 只是從parent_id - null
          return await this.fetchBranchesAndPrepareCandidates({ type: 'level', branch_id: '' })
        default:
          // 階級獲取 從特定分店開始
          return await this.fetchBranchesAndPrepareCandidates({ type: 'specific', branch_code: providerSetting[1] })
      }
    },
    // below are APIs for parent components to call
    allSelectedBranches() {
      const forkCandidateBranchModels = Object.values(_.cloneDeep(this.candidateBranchModels)).reduce((acc, value, index) => {
          acc[index] = value;
          return acc;
        }, {});
      return Object.values(this.selectedBranchIds)
        .filter(Boolean)
        .map((branchId, index) => forkCandidateBranchModels[index].find(branchModel => branchModel.id === branchId))
    },
    async fetchBranchesOptions() {
      if (!this.branchProvider) {
        throw new Error('[ShopSelection] Branch provider is not set.')
      }

      this.isFetchingData = true
      this.$emit('loading')

      if (this.value) {
        await this.loadBranchOptionsBySelectedShopId(this.value)
      } else {
        await this.$_fetchBranchesByProvider()
      }

      this.isFetchingData = false
      this.$emit('loaded')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.shop-selection select.s-form-control {
  width: 100%;
}
</style>
