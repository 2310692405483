<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>
    <div v-if="isChecked">
      <div class="page">
        <div
          v-if="getModuleConfig('form_page.step_section.enable_step_section')"
          class="p-3"
        >
          <SharedStep
            :steps="getModuleConfig('form_page.step_section.steps')"
          />
        </div>

        <div class="page__title">
          {{ getModuleConfig("form_page.form_title") }}
        </div>
        <div
          class="page__desc"
          v-html="getModuleConfig('form_page.form_desc')"
        ></div>

        <div class="form">
          <!-- fields -->
          <div
            v-for="(field, index) in fields.filter((x) => {
              return x.hidden == undefined || !x.hidden;
            })"
            :key="`field.${index}`"
            class="field"
          >
            <!-- mobile_phone -->
            <div v-if="field.type === 'mobile_phone'">
              <label
                :id="field.field_key"
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ displayTitle(field, "手機號碼") }}</label
              >

              <div class="d-flex">
                <input
                  class="field__input flex-fill"
                  v-model="form.mobile_phone"
                  type="tel"
                  :class="{ invalid: v$.form.mobile_phone.$error }"
                  :placeholder="displayPlaceholder(field, '請輸入您的手機號碼')"
                  :disabled="
                    field.disabled || isMobileValidating || mobileIsValid
                  "
                  @keypress="v$.$touch()"
                />
                <SharedButton
                  v-if="getModuleConfig('form_page.enable_sms_check')"
                  :disabled="
                    !form.mobile_phone ||
                    v$.form.mobile_phone.$error ||
                    isMobileValidating ||
                    mobileIsValid
                  "
                  class="ml-3 w-auto text-nowrap s-btn-bg-primary"
                  @click="sendMobile"
                >
                  <template v-if="isMobileValidating && counter > 0">
                    {{ counter }}秒後失效
                  </template>
                  <template v-else-if="mobileIsValid">已驗証</template>
                  <template v-else> 傳送驗證碼 </template></SharedButton
                >
                <button
                  v-if="
                    (!isMobileValidating &&
                      !mobileIsValid &&
                      user.phone_verified_at != null) ||
                    isMobileValidating
                  "
                  @click="
                    () => {
                      isMobileValidating = false;
                      mobileIsValid = user.phone_verified_at != null;
                      form.mobile_phone = user.mobile_phone;
                    }
                  "
                  class="btn btn-link text-nowrap ml-3"
                >
                  取消
                </button>
                <button
                  v-if="mobileIsValid && !isMobileValidating"
                  @click="mobileIsValid = false"
                  class="btn btn-link text-nowrap ml-3"
                >
                  修改
                </button>
              </div>
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div v-if="v$.form.mobile_phone.$error" class="form__error-msg">
                輸入的資料有誤或未填寫，請確認
              </div>
              <div v-if="mobileValidMessage" class="form__error-msg">
                {{ mobileValidMessage }}
              </div>
              <div v-if="v$.mobileIsValid.$invalid" class="form__error-msg">
                手機號碼未驗証
              </div>

              <div v-if="isMobileValidating" class="code-input d-flex">
                <div class="flex-fill">
                  <CodeInput
                    :loading="false"
                    :fields="6"
                    :fieldHeight="80"
                    :class="{ invalid: !codeIsValid }"
                    @change="(e) => (verificationCode = e)"
                  />
                  <div v-if="!codeIsValid" class="form__error-msg">
                    {{ codeValidMessage }}
                  </div>
                </div>
                <div class="ml-2">
                  <SharedButton
                    class="text-nowrap s-btn-bg-primary"
                    :disabled="verificationCode.length != 6"
                    @click="verifyMobile"
                    >確定</SharedButton
                  >
                </div>
              </div>
            </div>
            <!-- mobile_phone -->

            <!-- date -->
            <div v-else-if="field.type === 'date'">
              <label

                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ field.title }}</label
              >
              <div
                class="field__select-group"
                :class="{
                  invalid:
                    v$.form[`${field.field_key}-year`].$error ||
                    v$.form[`${field.field_key}-month`].$error ||
                    v$.form[`${field.field_key}-day`].$error,
                }"
              >
                <SharedSelect
                  :id="`${field.field_key}-year`"
                  v-model="form[`${field.field_key}-year`]"
                  :options="yearOptions"
                  :hasBorder="false"
                  placeholder="年份"
                  :disabled="field.disabled"
                  :class="{ invalid: v$.form[`${field.field_key}-year`].$error }"
                />
                <SharedSelect
                  :id="`${field.field_key}-month`"
                  v-model="form[`${field.field_key}-month`]"
                  :options="monthOptions"
                  :hasBorder="false"
                  placeholder="月份"
                  :disabled="field.disabled"
                  :class="{ invalid: v$.form[`${field.field_key}-month`].$error }"
                />
                <SharedSelect
                  :id="`${field.field_key}-day`"
                  v-model="form[`${field.field_key}-day`]"
                  :options="
                    dayOptions(
                      form[`${field.field_key}-year`],
                      form[`${field.field_key}-month`]
                    )
                  "
                  :hasBorder="false"
                  placeholder="日"
                  :disabled="field.disabled"
                  :class="{ invalid: v$.form[`${field.field_key}-day`].$error }"
                />
              </div>
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="
                  v$.form[`${field.field_key}-year`].$error ||
                  v$.form[`${field.field_key}-month`].$error ||
                  v$.form[`${field.field_key}-day`].$error
                "
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
            <!-- birthday -->

            <!-- address -->
            <div v-else-if="field.type === 'address'">
              <label

                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ displayTitle(field, "地址") }}</label
              >
              <div class="field__select-group mb-3 city-selector-set-has-value">
                <SharedSelect
                   :id="`${field.field_key}-city`"
                  v-model="form[`${field.field_key}-city`]"
                  :hasBorder="false"
                  selectClass="county"
                  :disabled="field.disabled"
                  :class="{ invalid: v$.form[`${field.field_key}-city`].$error }"
                />
                <SharedSelect
                :id="`${field.field_key}-district`"
                  v-model="form[`${field.field_key}-district`]"
                  :hasBorder="false"
                  selectClass="district"
                  :disabled="field.disabled"
                  :class="{ invalid: v$.form[`${field.field_key}-district`].$error }"
                />
              </div>
              <input
                :id="`${field.field_key}-address`"
                class="field__input"
                v-model="form[`${field.field_key}-address`]"
                :placeholder="displayPlaceholder(field, '請輸入詳細地址')"
                :disabled="field.disabled"
                :class="{
                  invalid:
                    v$.form[`${field.field_key}-address`].$error ||
                    v$.form[`${field.field_key}-district`].$error ||
                    v$.form[`${field.field_key}-city`].$error,
                }"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
            </div>
            <!-- email -->

            <!-- select type -->
            <div
              v-else-if="
                field.type === 'select' && field.matched_key && !field.provider
              "
            >
              <label
                :id="field.field_key"
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ field.title }}</label
              >
              <SharedSelect
                v-model="form[`${field.field_key}`]"
                :options="field.options"
                :hasBorder="false"
                :placeholder="displayPlaceholder(field)"
                :disabled="field.disabled"
                :class="{ invalid: v$.form[field.field_key].$error }"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.form[field.field_key].$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
            <!-- select type -->

            <!-- branch type -->
            <div
              v-else-if="
                field.type === 'branch' && field.matched_key && field.provider
              "
            >
              <label
                :id="field.field_key"
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ field.title }}</label
              >
              <ShopSelection
                :ref="`shop-selection-${field.field_key}`"
                v-model="form[`${field.field_key}`]"
                :branch-provider="field.provider"
                :show-branch-code="!!field.show_branch_code"
                :placeholder="displayPlaceholder(field, '請選擇分店櫃點')"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.form[`${field.field_key}`].$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
            <!-- branch type -->

            <!-- radio type -->
            <div v-else-if="field.type === 'radio' && field.matched_key">
              <label
                :id="field.field_key"
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ field.title }}</label
              >
              <div
                class="s-flex flex-wrap"
                :class="field.is_vertical && 'flex-column'"
              >
                <SharedRadioButton
                  v-for="(option, index) in field.options"
                  v-model="form[`${field.field_key}`]"
                  :id="field.title + index"
                  :key="field.title + index"
                  :value="option.value"
                  :name="field.matched_key"
                  :disabled="field.disabled"
                >
                  {{ option.text }}
                </SharedRadioButton>
              </div>
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.form[`${field.field_key}`].$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
            <!-- radio type -->

            <!-- checkbox type -->
            <div v-else-if="field.type === 'checkbox' && field.matched_key">
              <label
                :id="field.field_key"
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ field.title }}</label
              >
              <div class="s-flex">
                <b-form-checkbox-group
                  :options="field.options"
                  v-model="form[`${field.field_key}`]"
                  :stacked="field.is_vertical"
                  :disabled="field.disabled"
                ></b-form-checkbox-group>
              </div>
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.form[`${field.field_key}`].$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
            <!-- checkbox type -->

            <!-- text type -->
            <div v-else-if="field.matched_key">
              <label
                :id="field.field_key"
                class="field__label"
                :class="field.required === true ? 'field--required' : null"
                >{{ field.title }}</label
              >
              <input
                class="field__input"
                v-model="form[`${field.field_key}`]"
                :class="{ invalid: v$.form[field.field_key].$error }"
                :placeholder="displayPlaceholder(field)"
                :disabled="field.disabled"
              />
              <small v-if="field.memo" class="field__desc">{{
                field.memo
              }}</small>
              <div
                v-if="v$.form[field.field_key].$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
            <!-- text type -->
          </div>
          <!-- fields -->
        </div>
      </div>
      <div
        class="reminder"
        v-html="getModuleConfig('form_page.reminder')"
      ></div>
      <div
        class="d-flex"
        v-if="
          getModuleConfig('form_page.enable_terms_check') &&
          getModuleConfig('form_page.terms_check')
        "
        style="margin-left: 12px"
      >
        <b-form-checkbox
          class="my-auto ml-2"
          v-model="termCheck"
        ></b-form-checkbox>
        <div
          class="terms_check"
          v-html="getModuleConfig('form_page.terms_check')"
        ></div>
      </div>
      <div class="page__button">
        <SharedButton
          class="s-btn-bg-primary"
          @click="handleSubmit"
          :disabled="!termCheck"
          >送出</SharedButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import SharedStep from "@/components/Page/Liff/Shared/Steps";
import SharedRadioButton from "@/components/Page/Liff/Shared/RadioButton";
import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
import TwCitySelector from "tw-city-selector";
import registerApi from "@/apis/liff/v2/register";
import dateOptionMixin from "@/mixins/liff/dateOption";
import registerMixin from "@/mixins/liff/register";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { isValidJsonArray } from "@/utils/jsonHelper";
import branchMixin from "@/mixins/Dashboard/branches";
import CodeInput from "@/components/CodeInput";

const TIMEOUT = 120;

export default {
  mixins: [dateOptionMixin, registerMixin, branchMixin],
  components: {
    CodeInput,
    SharedSelect,
    SharedRadioButton,
    SharedButton,
    SharedStep,
    ShopSelection,
    Loading,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      isChecked: true,
      form: this.setForm(),
      fields: [],
      displayLoading: false,
      termCheck: true,
      verificationCode: "",
      counter: TIMEOUT,
      timer: null,
      codeIsValid: true,
      mobileIsValid: false,
      isMobileValidating: false,
      prePhoneNumber: null,
      mobileValidMessage: null,
      codeValidMessage: null,
    };
  },

  computed: {
    ...mapGetters("liffAuth", ["user"]),
  },

  validations() {
    let form = {};
    let mobileIsValid = {};

    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];
      // 特殊規則 手機
      if (field.type === "mobile_phone") {
        form.mobile_phone = this.isFieldRequired("mobile_phone")
          ? {
              required,
              mobile: (val) => {
                return /09[0-9]{8}/.test(val);
              },
            }
          : {};

        if (this.getModuleConfig("form_page.enable_sms_check")) {
          mobileIsValid = {
            isValid: (val) => {
              return val == true;
            },
          };
        }

        continue;
      }

      // 特殊規則 日期
      if (field.type === "date") {
        form[`${field.field_key}-year`] =
          field.required === true ? { required } : {};
        form[`${field.field_key}-month`] =
          field.required === true ? { required } : {};
        form[`${field.field_key}-day`] =
          field.required === true ? { required } : {};

        continue;
      }

      // 特殊規則 地址
      if (field.type === "address") {
        form[`${field.field_key}-city`] = this.isFieldRequired("address")
          ? { required }
          : {};
        form[`${field.field_key}-district`] = this.isFieldRequired("address")
          ? { required }
          : {};
        form[`${field.field_key}-address`] = this.isFieldRequired("address")
          ? { required }
          : {};
        continue;
      }

      // 一般欄位規則
      form[field.field_key] = field.required === true ? { required } : {};
    }

    return {
      form,
      mobileIsValid,
    };
  },

  created() {
    this.init();
  },

  mounted() {},

  methods: {
    isValidJsonArray,
    setForm() {
      //解決動態 validations 必須先定義 form 的 property

      var form = {};
      let fields = this.fetchFields();

      // Refill fields
      fields.forEach((field) => {
        let isExtra = field.matched_key.startsWith("extra.");
        let user = isExtra
          ? this.$store.state.liffAuth.user.extra
          : this.$store.state.liffAuth.user;

        // 特殊規則 地址
        if (field.type === "address") {
          // if (
          //   user[`${field.field_key}-city`] &&
          //   user[`${field.field_key}-city`] !== undefined
          // )
          form[`${field.field_key}-city`] = user[`${field.field_key}-city`];

          // if (
          //   user[`${field.field_key}-district`] &&
          //   user[`${field.field_key}-district`] !== undefined
          // )
          form[`${field.field_key}-district`] =
            user[`${field.field_key}-district`];

          // if (
          //   user[`${field.field_key}-address`] &&
          //   user[`${field.field_key}-address`] !== undefined
          // )
          form[`${field.field_key}-address`] =
            user[`${field.field_key}-address`];

          return;
        }

        // 特殊規則 日期
        if (field.type === "date") {
          const parseDate = new Date(
            this.$route.query[field.matched_key] ||
              field.value ||
              user[field.field_key]
          );

          form[`${field.field_key}-year`] = parseDate.getFullYear();
          form[`${field.field_key}-month`] = parseDate.getMonth() + 1;
          form[`${field.field_key}-day`] = parseDate.getDate();

          return;
        }

        // checkbox
        if (field.type === "checkbox") {
          form[field.field_key] =
            this.$route.query[field.matched_key] ||
            field.value ||
            this.isValidJsonArray(user[field.field_key])
              ? JSON.parse(user[field.field_key])
              : user[field.field_key];

          return;
        }

        // if (user[field.field_key] !== undefined) {
        //其他一般欄位
        form[field.field_key] =
          this.$route.query[field.matched_key] ||
          field.value ||
          user[field.field_key];

        return;
        //}
      });

      return { ...form };
    },
    async init() {
      this.displayLoading = true;
      let result = await this.checkRegistered();
      this.fields = this.fetchFields();
      this.fetchProviderFieldsOption();
      //this.fetchFields();

      if (result === false) {
        return;
      } else {
        this.displayLoading = false;
      }

      if (
        this.getModuleConfig("terms_page.enable") &&
        localStorage.getItem("register.checkTime") == null
      ) {
        this.$router.push({
          name: "LiffRegisterTerms",
          params: {
            orgCode: this.$route.params.orgCode,
          },
          query: this.$route.query,
        });

        return;
      } else {
        this.isChecked = true;
      }

      //Mobile Valid

      this.mobileIsValid =
        this.getModuleConfig("form_page.enable_sms_check") &&
        this.user.phone_verified_at;

      //Refill fields
      this.fields.forEach(async (field) => {
        let isExtra = field.matched_key.startsWith("extra.");
        let user = isExtra ? this.user.extra : this.user;

        // 特殊規則 地址
        if (field.type === "address") {
          // 之所以改到這裡，是為了在初始化 twCitySelector 時直接賦值 city & district
          new TwCitySelector({
            el: ".city-selector-set-has-value",
            elCounty: ".county",
            elDistrict: ".district",
            countyValue: user[`${field.field_key}-city`],
            districtValue: user[`${field.field_key}-district`],
          });

          return;
        }
      });

      if (
        this.getModuleConfig("form_page.enable_terms_check") &&
        this.getModuleConfig("form_page.terms_check")
      ) {
        this.termCheck = false;
      }
    },
    fetchFields() {
      let fields = this.getModuleConfig("member_fields");

      fields = _.map(fields, (field) => {
        let field_key;
        if (field.matched_key) {
          field_key = field.matched_key.startsWith("extra.")
            ? field.matched_key.substring(6)
            : field.matched_key;
        } else {
          field_key = field.type;
        }

        return {
          ...field,
          field_key,
        };
      });

      return _.sortBy(fields, "order");
    },
    isFieldShow(key) {
      return this.fields.some((field) => field.type === key);
    },
    isFieldRequired(key) {
      return this.fields.some(
        (field) => field.type === key && field.required == true
      );
    },
    displayPlaceholder(field, defaultMessage = null) {
      if (field.placeholder) return field.placeholder;
      if (defaultMessage) return defaultMessage;
      if (field.title) return "請選擇" + field.title;
      return "";
    },
    displayTitle(field, defaultMessage = null) {
      if (field.title) return field.title;
      if (defaultMessage) return defaultMessage;
      return "";
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) {

        console.log()

        const element = document.getElementById(this.v$.$errors[0].$property);
        element.scrollIntoView({ behavior: "smooth" });

        return;
      }

      if (!this.termCheck) {
        return;
      }

      if (!this.checkMobilePhone()) {
        return;
      }

      try {
        this.displayLoading = true;
        await this.doHandleSubmit();
        this.displayLoading = false;
      } catch (error) {
        this.displayLoading = false;
        console.error("error", error);
      }
    },
    async doHandleSubmit() {
      // 依照 module_config 中的 member_fields 組裝送到後端的資料
      let payload = { extra: {} };

      this.fields.forEach((field) => {
        let isExtra = field.matched_key.startsWith("extra.");
        let data = isExtra ? payload.extra : payload;

        //let fieldConfig = this.fields[i];

        // 如果是特殊類型的欄位
        if (field.type === "date") {
          data[field.field_key] = `${this.form[`${field.field_key}-year`]}-${
            this.form[`${field.field_key}-month`]
          }-${this.form[`${field.field_key}-day`]}`;
          return;
        }

        if (field.type === "address") {
          data[`${field.field_key}-city`] =
            this.form[`${field.field_key}-city`];
          data[`${field.field_key}-district`] =
            this.form[`${field.field_key}-district`];
          data[`${field.field_key}-address`] =
            this.form[`${field.field_key}-address`];
          return;
        }

        // // 如果是 extra 欄位
        // if (field.matched_key.startsWith("extra.")) {
        //   let fieldName = field.matched_key.substring(6);
        //   data[fieldName] = this.form[fieldName];
        //   //continue;
        // }

        // 一般欄位就不處理
        data[field.field_key] = this.form[field.field_key];
      });

      let response = await registerApi.register(payload);

      await this.fetchMe();

      this.$store.commit("liffAuth/SET_MOBILE", this.form.mobile_phone);

      let next = response.data.next ?? "success_page";

      if (next === "success_page") {
        this.$router.push({
          name: "LiffRegisterWelcome",
          params: {
            orgCode: this.$route.params.orgCode,
          },
        });
        return;
      }

      await this.goNext(next);
    },
    async fetchProviderFieldsOption() {
      this.$nextTick(() => {
        this.fields
          .filter(f => f.provider)
          .map(field => {
            this.$refs[`shop-selection-${field.field_key}`][0].fetchBranchesOptions()
          })
      })
    },
    async sendMobile() {
      if (this.counter > 0 && this.prePhoneNumber == this.form.mobile_phone) {
        return this.isMobileValidating = true;
      }

      this.prePhoneNumber = this.form.mobile_phone;

      await registerApi
        .sendPhone(this.form.mobile_phone)
        .then((response) => {
          if (response.data.status == "success") {
            this.startTimer();
            this.isMobileValidating = true;
            this.mobileValidMessage = null;
          }
        })
        .catch((error) => {
          this.mobileValidMessage = error.response.data.message;
        });
    },
    async verifyMobile() {
      await registerApi
        .verifyByMobile(this.verificationCode, this.form.mobile_phone)
        .then((response) => {
          if (response.data.status == "success") {
            this.fetchMe();

            this.codeIsValid = this.mobileIsValid = true;
            this.isMobileValidating = false;
            this.codeValidMessage = null;
          }
        })
        .catch((error) => {
          this.codeIsValid = this.mobileIsValid = false;
          this.codeValidMessage = error.response.data.message;
        });

      //this.mobileIsValid = false;
    },

    startTimer() {
      this.clearTimer();
      this.counter = TIMEOUT;
      this.timer = window.setInterval(() => {
        this.counter--;

        if (this.counter > 0) {
          return;
        }

        this.clearTimer();
      }, 1000);
    },
    clearTimer() {
      if (!this.timer) {
        return;
      }

      window.clearInterval(this.timer);
      this.timer = null;
    },
    // 檢查手機號碼格式
    checkMobilePhone() {
      // 如果沒有設定手機號碼 直接過
      if (!this.fields.find((f) => f.matched_key == "mobile_phone")) {
        return true;
      }
      let phone = this.form.mobile_phone;
      let formattedPhone = phone;

      // 長度大於 10 碼的處理
      if (phone.length > 10) {
        if (phone.startsWith("+886")) {
          // 如果 +886 後面接 09，一樣把 +886 去除後，補回 09
          formattedPhone = phone.startsWith("+88609")
            ? phone.substring(4)
            : "0" + phone.substring(4);
        } else if (phone.startsWith("886")) {
          // 如果 886 後面接 09，一樣把 886 去除後，補回 09
          formattedPhone = phone.startsWith("88609")
            ? phone.substring(3)
            : "0" + phone.substring(3);
        } else if (phone.startsWith("+")) {
          formattedPhone = phone.substring(1);
        } else {
          this.$swal("提醒", "手機格式錯誤", "warning");
          return false;
        }
      }

      // 根據長度進行不同的處理
      switch (formattedPhone.length) {
        case 10:
          // 檢查是否以 "09" 開頭
          if (!formattedPhone.startsWith("09")) {
            this.$swal("提醒", "手機格式錯誤", "warning");
            return false;
          }
          // 是否全部都數字
          if (!/^\d+$/.test(formattedPhone)) {
            this.$swal("提醒", "手機格式錯誤", "warning");
            return false;
          }
          break;
        case 9:
          // 檢查是否以 "9" 開頭並補 "0"
          if (formattedPhone.startsWith("9")) {
            formattedPhone = "0" + formattedPhone;
          } else {
            this.$swal("提醒", "手機格式錯誤", "warning");
            return false;
          }
          // 是否全部都數字
          if (!/^\d+$/.test(formattedPhone)) {
            this.$swal("提醒", "手機格式錯誤", "warning");
            return false;
          }

          break;
        default:
          // 長度不符合條件
          this.$swal("提醒", "手機格式錯誤", "warning");
          return false;
      }

      // 更新手機號碼並繼續後續處理
      this.form.mobile_phone = formattedPhone;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.reminder {
  font-size: 12px;
  color: var(--liff-secondary_text_color);
  margin: 12px;
  a {
    text-decoration: underline;
  }
}

.terms_check {
  color: var(--liff-primary_text_color);
  margin: 12px;
  font-size: 16px;
  ::v-deep a {
    text-decoration: underline;
    color: var(--s-primary);
  }
}

.code-input {
  margin-bottom: 32px;
  margin-top: 20px;

  .react-code-input-container {
    width: auto !important;
  }
  ::v-deep .react-code-input-container {
    margin: auto;
    text-align: center;

    &.invalid input {
      border: 1px solid #fe0000;
    }
  }
  ::v-deep input {
    border: 1px solid #636366 !important;
    border-radius: 10px !important;
    font-size: 18px;
    font-weight: 600;
    width: 100% !important;
    height: 50px !important;
    font-family: PingFang TC;
    color: var(--primary-color) !important;

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
      caret-color: var(--liff-primary_text_color);
    }
    &:focus + input {
      border: 1px solid #636366;
    }
  }
}
</style>
