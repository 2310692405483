<template>
  <div class="s-item">
    <div class="s-item-info">
      <div class="s-space-y-2 s-mr-auto w-full">
        <template v-if="fields.length > 0">
          <p
            v-for="(field, i) in fields"
            class="s-text-gray s-text-xs s-whitespace-nowrap"
            v-bind:key="i"
          >
            {{ field.value }}{{ getFieldValue(field) }}
          </p>
        </template>
        <template v-else>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">
            預約時間：{{ bookingDateText }}
          </p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">
            預約館別：{{ booking.location_name }}
          </p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">
            醫師：{{ booking.consultant_name }}
          </p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">
            項目：{{ booking.service }}
          </p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">
            預約狀態：{{ booking.status }}
          </p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">
            諮詢師：{{ booking.operator_name }}
          </p>
        </template>
        <div class="w-full d-flex align-items-start justify-content-end">
          <div v-for="(sdConfig, index) in statusDetectorConfig" v-bind:key="index" >
            <StatusDetector
              :status="booking.status"
              :config="sdConfig"
              enviroment="liff"
              @buttonAction="updateStatus"
            />
          </div>
          <div v-if="isCanEdit" @click="goToEdit()">編輯</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StatusDetector from "@/components/StatusDetector.vue";
import bookingApi from "@/apis/liff/v2/booking";

export default {
  components: {
    StatusDetector,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    preset: {
      type: Object,
    },
    fieldSetting: {
      type: Object,
      required: false,
    },
    bookingStatusMapping: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    statusDetectorConfig: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    fields() {
      let listSetting = this.fieldSetting?.list || [];
      if (listSetting) {
        return listSetting
          .filter((field) => field.is_show)
          .sort((a, b) => a.order - b.order);
      } else {
        return [];
      }
    },
    isCanEdit() {
      if (this.preset && this.preset?.config?.can_edit_at_liff_mapping) {
        return this.preset.config.can_edit_at_liff_mapping.includes(this.booking.status);
      } else {
        return false
      }
    },
    bookingDateText() {
      const startAt = moment(new Date(this.booking.start_at));
      const endAt = moment(new Date(this.booking.end_at));
      if (!this.booking.end_at) {
        return `${startAt.format("YYYY-MM-DD HH:mm")}`;
      }
      if (!this.booking.start_at) {
        return `${endAt.format("YYYY-MM-DD HH:mm")}`;
      }
      if (startAt.isSame(endAt, 'minute')) {
        return `${startAt.format("YYYY-MM-DD HH:mm")}`;
      }
      if (startAt.isSame(endAt, 'day')) {
        return `${startAt.format("YYYY-MM-DD HH:mm")} ~ ${endAt.format(
          "HH:mm"
        )}`;
      }
      return `${startAt.format("YYYY-MM-DD HH:mm")} ~ ${endAt.format(
        "YYYY-MM-DD HH:mm"
      )}`;
    },
    statusText() {
      return (
        this.bookingStatusMapping[this.booking.status] ?? this.booking.status
      );
    },
  },
  methods: {
    async updateStatus(value) {
      await bookingApi.updateStatus(this.booking.id, { status: value });
      this.$emit("fetch");
    },
    goToEdit() {
      this.$router.push({
        name: "LiffBookingEdit",
        params: {
          bookingId: this.booking.id,
          bookingPresetId: this.preset.id,
          orgCode: this.$route.params.orgCode
        }
      })
    },
    getFieldValue(field) {
      if (field.key == "booking_date") {
        return this.bookingDateText;
      } else if (field.key == "status") {
        return this.statusText;
      } else if (field.key.startsWith("booking_data.")) {
        const [, fieldId] = field.key.split(".");
        const item = this.booking.data.find(
          (item) => item.field_id === fieldId
        );
        return item ? item.value : "";
      }
      return this.booking[field.key];
    },
  },
};
</script>

<style scoped>
.s-item {
  display: flex;
  align-items: center;
  background-color: var(--s-white);
}

.s-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;

  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0px 2px 8px 0px;
  border-radius: 5px;
}

.w-full {
  width: 100%;
}
</style>
