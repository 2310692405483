<template>
  <Loading v-if="isLoading" />
</template>

<script>
import benefitCardApi from "@/apis/liff/v2/benefit-card";
import Loading from "@/components/Page/Liff/Shared/Loading";
import _ from "lodash";

export default {
  components: {
    Loading,
  },
  data: () => ({
    isLoading: true,
  }),
  async mounted() {
    try {
      const res = await benefitCardApi.init('elc')
      const benefitCardCustomerId = _.get(res, 'data.benefit_card_customer_id')
      if (benefitCardCustomerId) {
        this.$router.push({ name: 'LiffBenefitCardHome', params: { benefitCardCustomerId } })
      } else {
        console.warn('Failed to retrieve benefit card for customer.')
      }
    } catch (e) {
      this.isLoading = false
      console.error(e)
      this.$swal({
        type: 'error',
        text: _.get(e, 'response.data.message', '領取優惠券失敗'),
        showCancelButton: false,
        backdrop: false,
        reverseButtons: true,
        confirmButtonText: '返回會員中心',
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: 'LiffMemberCenterHome' })
        }
      })
    }
  },
};
</script>
