import https from "./https";
import store from "@/store";

const organization = store.state.general.organization;

const booking = {
  // 預約設定檔 booking_presets
  getBookingPresets(params) {
    return https.get(`admin/organizations/${organization.id}/booking-presets`, {
      params,
    });
  },
  createBookingPreset(params) {
    return https.post(
      `admin/organizations/${organization.id}/booking-presets`,
      params
    );
  },
  getBookingPreset(id, params = {}) {
    return https.get(
      `admin/organizations/${organization.id}/booking-presets/${id}`, {
        params
      });
  },
  updateBookingPreset(id, params) {
    return https.put(
      `admin/organizations/${organization.id}/booking-presets/${id}`,
      params
    );
  },
  deleteBookingPreset(id) {
    return https.delete(
      `admin/organizations/${organization.id}/booking-presets/${id}`
    );
  },
  // 預約記錄 bookings
  getBookings(presetId, params) {
    return https.get(
      `admin/organizations/${organization.id}/booking-presets/${presetId}/bookings`,
      { params }
    );
  },
  getBookingOptions(presetId, params) {
    return https.get(`admin/organizations/${organization.id}/booking-presets/${presetId}/bookings/options`, { params });
  },
  createBooking(presetId, params) {
    return https.post(
      `admin/organizations/${organization.id}/booking-presets/${presetId}/bookings`,
      params
    );
  },
  getBooking(presetId, bookingId) {
    return https.get(
      `admin/organizations/${organization.id}/booking-presets/${presetId}/bookings/${bookingId}`
    );
  },
  getBookingFormatData(presetId, bookingId) {
    return https.get(
      `admin/organizations/${organization.id}/booking-presets/${presetId}/bookings/${bookingId}/format-data`
    );
  },
  updateBooking(presetId, bookingId, params) {
    return https.put(
      `admin/organizations/${organization.id}/booking-presets/${presetId}/bookings/${bookingId}`,
      params
    );
  },
  deleteBooking(presetId, bookingId) {
    return https.delete(
      `admin/organizations/${organization.id}/booking-presets/${presetId}/bookings/${bookingId}`
    );
  },
  updateBookingStatus(presetId, bookingId, params) {
    return https.post(`admin/organizations/${organization.id}/booking-presets/${presetId}/bookings/${bookingId}/status`, params);
  },

  setupBookingPresetBranch(presetId, branchId, params) {
    return https.put(`admin/organizations/${organization.id}/booking-presets/${presetId}/branch/${branchId}`, params);
  },
}

export default booking;
