<template>
  <div
    :class="{ 'is-flex': getModuleConfig('card_layout') === 'flex' }"
    class="d-flex flex-column min-vh-100 content__background"
    :style="getModuleConfig('layout.display_bg_image')&&{'background-image':`url(${getModuleConfig('layout.bg_image')})`}"
  >
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <div class="top" :style="topStyle">
      <div
        :class="`layout layout-${getModuleConfig(
          'member_card_section.background_layout'
        )}`"
        :style="topLayoutImage"
      >
        <Header
          :meta="meta"
          :page-title="getModuleConfig('page_title')"
          :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')"
          :header-section="getModuleConfig('header_section')"
        />
        <div class="py-3 px-2">
          <div
            v-if="getModuleConfig('member_card_section.enable') == true"
            class="card"
            :class="`layout-${getModuleConfig(
              'member_card_section.card_layout'
            )}`"
            :style="cardBackground"
          >
            <div class="card__logo">
              <img
                v-if="getModuleConfig('member_card_section.display_logo')"
                :src="memberCardLevelLogo"
                :style="{
                  width: getModuleConfig(
                    'member_card_section.logo_style.width'
                  ),
                  transform: `translate(${
                    getModuleConfig(
                      'member_card_section.logo_style.translateX'
                    ) || '0'
                  },${
                    getModuleConfig(
                      'member_card_section.logo_style.translateY'
                    ) || '0'
                  })`,
                }"
              />
            </div>

            <div
              class="card__name"
              v-if="getModuleConfig('member_card_section.display_name') == true"
            >
              {{ modifiedUser.name }}
            </div>
            <div class="card__avatar">
              <img
                v-if="
                  getModuleConfig(
                    'member_card_section.display_customer_avatar'
                  ) == true
                "
                :src="modifiedUser.avatar_url"
                class="rounded-circle"
              />
            </div>

            <div
              class="card__level"
              :style="{
                color: `${getModuleConfig(
                  'member_card_section.customer_level_name_color'
                )}`,
                'border-color': `${getModuleConfig(
                  'member_card_section.customer_level_name_color'
                )}`,
                visibility: `${
                  getModuleConfig(
                    'member_card_section.display_customer_level_name'
                  ) && modifiedUser.loyalty.level_name
                    ? 'visible'
                    : 'hidden'
                }`,
              }"
            >
              <template
                v-if="
                  getModuleConfig(
                    'member_card_section.display_customer_level_name'
                  ) == true && modifiedUser.loyalty.level_name
                "
              >
                {{ modifiedUser.loyalty.level_name }}
              </template>
            </div>

            <div class="card__point">
              <div
                v-if="
                  getModuleConfig('member_card_section.display_points') == true
                "
                class="card__point__title"
                :style="{
                  color: `${getModuleConfig(
                    'member_card_section.customer_points_name_color'
                  )}`,
                }"
              >
                {{ displayPointsName }}
              </div>
              <div
                v-if="
                  getModuleConfig('member_card_section.display_points') == true
                "
                class="card__point__number"
                :style="{
                  color: `${getModuleConfig(
                    'member_card_section.customer_points_color'
                  )}`,
                }"
              >
                {{ userPoints }}
              </div>
            </div>

            <div
              class="card__minibarcode"
              :style="{
                visibility: `${
                  getModuleConfig('member_card_section.display_mini_barcode') ==
                  true
                    ? 'visible'
                    : 'hidden'
                }`,
              }"
            >
              <template
                v-if="
                  getModuleConfig('member_card_section.display_mini_barcode') ==
                  true
                "
              >
                <button @click="$refs.minibarcode.open()">
                  <img src="./images/mini-barcode.svg" />
                </button>

                <div class="barcode-modal">
                  <popup ref="minibarcode" :noActions="true" :scrolling="true">
                    <div class="card p-4">
                      <p v-if="showBarcodeError" class="s-text-danger">
                        barcode 產生錯誤，請聯絡相關人員
                      </p>
                      <img v-else id="card__barcode-img" :src="barcode" />
                    </div>
                  </popup>
                </div>
              </template>
            </div>
          </div>

          <div
            class="card__barcode my-3"
            v-if="
              getModuleConfig('member_card_section.display_barcode') == true
            "
          >
            <!-- <img class="" src="./images/barcode.png" /> -->
            <p v-if="showBarcodeError" class="s-text-danger">
              barcode 產生錯誤，請聯絡相關人員
            </p>
            <img v-else id="card__barcode-img" :src="barcode" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex-fill body"
      :style="bodyStyle"
    >
      <div v-if="isSectionLoading" class="text-center my-2">
        <b-spinner class="pl-6" variant="secondary"></b-spinner>
      </div>

      <template v-else>
        <div v-for="(section, index) in sections" :key="'section' + index">
          <!-- menu_icon_section start -->
          <div v-if="section.key === 'menu_icon_section'" class="p-2">
            <div class="function">
              <div
                v-for="(i, idx) in section.menu_items.filter(
                  (x) => !x.is_hidden
                )"
                class="function__item p-2"
                :style="{ 'flex-basis': `${100 / section.column_number}%` }"
                :key="idx"
              >
                <template v-if="i.is_external">
                  <a :target="i.target ? i.target : '_blank'" :href="i.url">
                    <div
                      v-if="
                        i.icon_url == undefined ||
                        i.icon_url.split('.').pop() == 'svg'
                      "
                      class="function__icon__wrapper"
                    >
                      <img
                        class="function__icon"
                        :style="{
                          maskImage: getIconMask(i),
                          webkitMaskImage: getIconMask(i),
                        }"
                      />
                    </div>
                    <img v-else class="function__icon" :src="i.icon_url" />

                    <div class="function__title">{{ i.title }}</div>
                  </a>
                </template>
                <template v-else>
                  <router-link
                    :to="
                      i.url
                        ? i.url
                        : moduleMap.find((x) => x.type == i.type)
                        ? {
                            name: moduleMap.find((x) => x.type == i.type).value,
                            params: {
                              org_code:
                                $route.params.orgCode || $route.params.org_code,
                              orgCode:
                                $route.params.orgCode || $route.params.org_code,
                            },
                          }
                        : '/'
                    "
                  >
                    <div
                      v-if="
                        i.icon_url == undefined ||
                        i.icon_url.split('.').pop() == 'svg'
                      "
                      class="function__icon__wrapper"
                    >
                      <img
                        class="function__icon"
                        :style="{
                          maskImage: getIconMask(i),
                          webkitMaskImage: getIconMask(i),
                        }"
                      />
                    </div>
                    <img v-else class="function__icon" :src="i.icon_url" />

                    <div class="function__title">{{ i.title }}</div>
                  </router-link>
                </template>
              </div>
            </div>
          </div>
          <!-- menu_icon_section end -->

          <!-- menu_list_section start -->
          <div v-if="section.key === 'menu_list_section'">
            <div class="menu">
              <span v-for="(i, idx) in section.menu_items" :key="idx">
                <router-link
                  v-if="!i.is_external"
                  class="menu__item"
                  :to="
                    i.url
                      ? i.url
                      : moduleMap.find((x) => x.type == i.type)
                      ? {
                          name: moduleMap.find((x) => x.type == i.type).value,
                          params: { org_code: $route.params.orgCode },
                        }
                      : '/'
                  "
                >
                  <span class="menu__title">{{ i.title }}</span>
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2773 9.85059C13.2773 9.65723 13.1982 9.48144 13.0576 9.34961L6.44824 3.00391C6.32519 2.88086 6.17578 2.81055 5.99121 2.81055C5.62207 2.81055 5.34082 3.0918 5.34082 3.45215C5.34082 3.62793 5.41113 3.79492 5.52539 3.91797L11.6953 9.8418L5.52539 15.7744C5.41113 15.8975 5.34082 16.0557 5.34082 16.2314C5.34082 16.6006 5.62207 16.8818 5.99121 16.8818C6.17578 16.8818 6.3252 16.8027 6.44824 16.6885L13.0576 10.334C13.1982 10.1934 13.2773 10.0352 13.2773 9.85059Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </router-link>
                <a
                  v-else
                  class="menu__item"
                  :href="i.url"
                  :target="i.target ? i.target : '_blank'"
                >
                  <span class="menu__title">{{ i.title }}</span>
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2773 9.85059C13.2773 9.65723 13.1982 9.48144 13.0576 9.34961L6.44824 3.00391C6.32519 2.88086 6.17578 2.81055 5.99121 2.81055C5.62207 2.81055 5.34082 3.0918 5.34082 3.45215C5.34082 3.62793 5.41113 3.79492 5.52539 3.91797L11.6953 9.8418L5.52539 15.7744C5.41113 15.8975 5.34082 16.0557 5.34082 16.2314C5.34082 16.6006 5.62207 16.8818 5.99121 16.8818C6.17578 16.8818 6.3252 16.8027 6.44824 16.6885L13.0576 10.334C13.1982 10.1934 13.2773 10.0352 13.2773 9.85059Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                </a>
              </span>
            </div>
          </div>
          <!-- menu_list_section end -->

          <!-- 會員資料 member_detail_section start -->
          <div v-if="section.key === 'member_detail_section'" class="p-2 pb-0">
            <div
              v-for="(group, idx) in detail_fields"
              class="detail mb-2"
              :key="idx"
            >
              <div class="detail__wrapper">
                <div
                  v-for="(field, index2) in group"
                  :key="'field' + index2"
                  class="detail__item"
                  :class="field.col_span"
                >
                  <div class="detail__title">{{ field.title }}</div>
                  <div class="detail__value">
                    {{ getFieldDisplayValue(field) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 會員資料 member_detail_section end -->

          <!-- 點數 member_point_section start -->
          <div v-if="section.key === 'member_point_section'">
            <div class="points-list">
              <div
                class="points-list__item"
                v-for="item in points"
                :key="item.id"
              >
                <div class="points-list__title">2022/12/31 到期點數</div>
                <div class="points-list__point">{{ item.point }}</div>
              </div>
            </div>
            <div>
              <Pagination
                v-model="page"
                :totalPage="points.length"
              ></Pagination>
            </div>
          </div>
          <!-- 點數 member_point_section end -->

          <!-- highlight_section start-->
          <div v-if="section.key === 'highlight_section'" class="p-2">
            <div v-for="(group,idx) in highlight_fields" :key="idx" class="highlight row">
              <div v-for="(field,fdx) in group" :key="fdx" :class="field.col_span=='full'?'col-12':'col'">
                <div class="highlight__title">
                  {{ field.title }}
                </div>
                <div class="highlight__value">{{getFieldDisplayValue(field)}}</div>
              </div>
            </div>
          </div>
          <!-- highlight_section end-->
          <!-- level_progress_section start-->
          <div v-if="section.key === 'level_progress_section'" class="p-2">
            <div class="level_progress">
              <div class="level_progress__period mb-2">
                VIP卡效期：<span class="s-text-primary"
                  >2024/01/01 ~ 2024/12/31</span
                >
              </div>

              <div class="mb-2">
                <span class="level_progress__amount">$68,488</span
                ><span class="mx-1">/</span>$88,888
              </div>

              <div class="level_progress__progress progress mb-2">
                <div class="progress-bar w-75" role="progressbar"></div>
              </div>

              累積消費，升等VVIP會員
            </div>
          </div>
          <!-- level_progress_section end-->
        </div>

        <div v-if="getModuleConfig('intro_section.enable')" class="p-2">
          <div class="info">
            <div class="px-3 py-2">
              <h3 class="info__title">
                {{ getModuleConfig("intro_section.form_title") }}
              </h3>
              <div class="info__desc">
                {{ getModuleConfig("intro_section.form_desc") }}
              </div>
            </div>
            <div
              class="info__content"
              v-html="getModuleConfig('intro_section.content')"
            ></div>
          </div>
        </div>

        <div
          v-if="
            getModuleConfig('unbind_button') &&
            getModuleConfig('unbind_button').enable
          "
          class="p-2 text-center text-small"
        >
          <router-link
            :to="{ name: 'LiffMemberCenterUnbind' }"
            class="text-underline"
          >
            <u>{{ getModuleConfig("unbind_button").button_text }}</u>
          </router-link>
        </div>

        <div
          v-if="
            getModuleConfig('footer_section') &&
            getModuleConfig('footer_section').enable
          "
          class="p-2 text-center text-small"
        >
          <div v-html="getModuleConfig('footer_section').content"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Page/Liff/Shared/Pagination";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import { mapGetters } from "vuex";
import bwipjs from "bwip-js";
import _ from "lodash";
import pointApi from "@/apis/liff/v2/point";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";
import authApi from "@/apis/liff/v2/auth";
import merchantApi from "@/apis/liff/v2/merchant";
import Popup from "@/components/Page/Liff/Shared/Popup.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";

const requireImage = (path) =>
  require(`@/assets/images/liff/module-icon/${path}`);

export default {
  mixins: [moduleMap],
  components: {
    Pagination,
    Header,
    Popup,
    Loading,
  },
  props: {
    isFlexLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sections: [],
      detail_fields: [],
      highlight_fields:[],
      showBarcodeError: false,
      barcode: null,
      page: 1,
      userPoints: "-",
      merchants: [],
      isLoading: true,
      isSectionLoading: true,
      loyaltyLevel: {
        level_name: "",
        logo_url: "",
        card_picture_url: "",
      },
      availablePoints: {
        point: 0,
        expired_at: null,
        expiring: []
      },
    };
  },
  computed: {
    ...mapGetters("liffAuth", ["user"]),
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    memberCardLevelLogo() {
      return (this.getModuleConfig('member_card_section.use_level_logo') &&
        this.modifiedUser.loyalty.logo_url) ||
        this.getModuleConfig('member_card_section.logo')
    },
    displayPointsName() {
      return (
        this.getModuleConfig("member_card_section.points_name") ?? "紅利點數"
      );
    },
    modifiedUser() {
      return {
        ...this.user,
        loyalty: this.loyaltyLevel,
        available_points: this.availablePoints,
      }
    },
    address() {
      if (!this.modifiedUser || !this.modifiedUser.extra) {
        return null;
      }
      return (
        (this.modifiedUser.extra.city || "") +
        (this.modifiedUser.extra.district || "") +
        (this.modifiedUser.extra.address || "")
      );
    },
    // groupedDetailFields() {
    //   return this.detail_fields.map((x) => {
    //     return Array.isArray(x) ? x : [x];
    //   });
    // },
    topStyle() {
      let otherStyle = {};
      if (this.getModuleConfig("member_card_section")) {
        otherStyle = this.checkColorValue(
          this.getModuleConfig("member_card_section.layout_bg_top_color") || "#FFFFFF00",
          "--liff-card_bg_top_color",
          otherStyle
        );
        otherStyle = this.checkColorValue(
          this.getModuleConfig("member_card_section.layout_bg_bottom_color") || "#FFFFFF00",
          "--liff-card_bg_bottom_color",
          otherStyle
        );
        otherStyle = this.checkColorValue(
          this.getModuleConfig("member_card_section.customer_name_color"),
          "--liff-card_name_text_color",
          otherStyle
        );
      }
      return otherStyle;
    },

    bodyStyle() {
      let otherStyle = {};
      if (this.getModuleConfig("content")) {
        otherStyle = this.checkColorValue(
          this.getModuleConfig("content.bg_color"),
          "--liff-body_bg_top_color",
          otherStyle
        );
        otherStyle = this.checkColorValue(
          this.getModuleConfig("content.bg_bottom_color") || this.getModuleConfig("content.bg_color"),
          "--liff-body_bg_bottom_color",
          otherStyle
        );
      }
      return otherStyle;
    },

    cardBackground() {
      let style = [];

      if (this.getModuleConfig("member_card_section.display_card_luster")) {
        style.push(
          `linear-gradient(135deg,${this.getModuleConfig(
            "member_card_section.card_luster_left_left"
          )} 0%,${this.getModuleConfig(
            "member_card_section.card_luster_left_right"
          )} 50%,${this.getModuleConfig(
            "member_card_section.card_luster_right_left"
          )} 50%,${this.getModuleConfig(
            "member_card_section.card_luster_right_right"
          )} 100%)`
        );
      }
      // else {
      //   style.push(
      //     `linear-gradient(135deg,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, .05) 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, 0) 100%)`
      //   );
      // }

      if (this.getModuleConfig("member_card_section.display_card_picture")) {
        if (
          this.getModuleConfig("member_card_section.use_level_card_picture") &&
          this.modifiedUser.loyalty.card_picture_url
        ) {
          style.push(`url('${this.modifiedUser.loyalty.card_picture_url}')`);
        } else if (
          this.getModuleConfig("member_card_section.card_picture_url")
        ) {
          style.push(
            `url('${this.getModuleConfig(
              "member_card_section.card_picture_url"
            )}')`
          );
        }
      }

      return { background: style.join(",") };
    },
    topLayoutImage() {
      const color = encodeURIComponent(
        this.getModuleConfig("content.bg_color")
      );

      switch (this.getModuleConfig("member_card_section.background_layout")) {
        case "B":
          return {
            backgroundImage: `url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 375 42" style="enable-background:new 0 0 375 42;" xml:space="preserve"><style type="text/css">.st0{fill:${color}; -webkit-filter: drop-shadow( 0px -5px 5px rgba(0, 0, 0, .3)); filter: drop-shadow( 0px -5px 5px rgba(0, 0, 0, .3));}</style><g><path class="st0" d="M0,42h187.5C72,42,0,10,0,10V42z"/><path class="st0" d="M187.5,42H375V10C375,10,303,42,187.5,42z"/></g></svg>')`,
          };
        case "C":
          return {
            backgroundImage: `url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 375 100" style="enable-background:new 0 0 375 100;" xml:space="preserve"><style type="text/css">.st0{fill:${color}; -webkit-filter: drop-shadow( 0px -5px 5px rgba(0, 0, 0, .3)); filter: drop-shadow( 0px -5px 5px rgba(0, 0, 0, .3));}</style><path class="st0" d="M325,60H0v40h375V60V10C375,37.6,352.6,60,325,60z"/></svg>')`,
          };
        case "D":
          return {
            backgroundImage: `url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 375 45" style="enable-background:new 0 0 375 45;" xml:space="preserve"><style type="text/css">.st0{fill:${color}; -webkit-filter: drop-shadow( 0px -5px 5px rgba(0, 0, 0, .3)); filter: drop-shadow( 0px -5px 5px rgba(0, 0, 0, .3));}</style><path class="st0" d="M295,12.2c38.5,4.3,62.5,18.2,80,28.1V45H0V10c50,32.5,115,28,164.5,21.4C210,25.2,256.5,8,295,12.2z"/></svg>')`,
          };
        case "A":
        default:
          return {};
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    checkColorValue(config, styleName, styleObject) {
      if (config !== undefined && config !== "#" && config !== "") {
        return Object.assign({ [styleName]: config }, styleObject);
      }
      return styleObject;
    },
    getFieldDisplayValue(field) {
      let customer = this.modifiedUser;
      let customerMerchant = false;
      if (field.type === "address") {
        return this.address();
      }
      if (field.merchant_type) {
        customerMerchant = this.merchants.find(
          (merchant) => merchant.type === field.merchant_type
        );
        if (customerMerchant) {
          customer = this.modifiedUser.users?.find(
            (item) => item.type === field.merchant_type
          );
        } else {
          customer = false;
        }
      }
      if (customer) {
        if (field.is_extra) {
          return customer.extra[field.field_name] ?? "";
        }else if (field.is_loyalty) {
          return customer.loyalty[field.field_name] ?? "";
        } else if (field.is_available_point) {
          return customer.available_points[field.field_name] ?? "";
        } else {
          return customer[field.field_name] ?? "";
        }
      }
      // 如果org裡面有merchant，但是沒有資料，則顯示空白
      if (customerMerchant) {
        return "";
      }
      // 如果merchant是錯的，則顯示不支援
      return "不支援";
    },
    async init() {
      // 檢查是否有啟用強制綁定及綁定狀態
      let { isEnabledForceBind, execBindResult } =
        await authApi.checkForceBind();

      if (
        isEnabledForceBind &&
        execBindResult === "external_customer_not_found"
      ) {
        this.$router.push({
          name: "LiffRegisterNotRegister",
        });
      }

      // 只有當啟用顯示 barcode 時，才 generate barcode

      if (
        this.getModuleConfig("member_card_section.display_barcode") === true ||
        this.getModuleConfig("member_card_section.display_mini_barcode") ===
          true
      ) {
        this.generateBarcode();
      }

      if (this.getModuleConfig("data_source.load_point") === true) {
        const data = await pointApi.getAvailablePoints();
        this.userPoints = data.point;
        this.availablePoints = data
      }

      if (this.getModuleConfig("data_source.load_customer_level") === true) {
        const loyalty = await pointApi.getLoyaltyLevel();
        this.loyaltyLevel = loyalty.data
      }

      this.isLoading = false;

      await this.getMerchants();

      // 排序 section
      let sectionKeys = [
        "menu_icon_section",
        "menu_list_section",
        "member_card_section",
        "member_point_section",
        "member_detail_section",
        "highlight_section",
        "level_progress_section",
      ];
      let sections = [];
      for (let i = 0; i < sectionKeys.length; i++) {
        let config = this.getModuleConfig(sectionKeys[i]);

        if (config) {
          config.key = sectionKeys[i];

          if (config.enable === true) {
            sections.push(config);
          }
        }
      }
      this.sections = _.sortBy(sections, "order");

      // 排序 menu_icon_section
      if (this.sections.some((x) => x.key == "menu_icon_section")) {
        let section = this.sections.find((x) => x.key == "menu_icon_section");
        section.menu_items = _.sortBy(section.menu_items, "order");
      }

      // 排序 menu_list_section
      if (this.sections.some((x) => x.key == "menu_list_section")) {
        let section = this.sections.find((x) => x.key == "menu_list_section");
        section.menu_items = _.sortBy(section.menu_items, "order");
      }

      // 排序 detail fields
      let memberDetailSection = this.getModuleConfig("member_detail_section");

      if (memberDetailSection.enable === true) {
        let groups = memberDetailSection.fields.filter((field) => {
          return Array.isArray(field);
        });

        groups = groups.map((fields) => {
          //let fields = Array.isArray(group[0]) ? group : [group];

          return _.sortBy(
            fields.map((field) => {
              let merchantType =
                field.matched_key?.split(":").length == 2
                  ? field.matched_key.split(":")[0].toUpperCase()
                  : false;
              let isExtra = field.matched_key?.includes("extra.");
              let isLoyalty = field.matched_key?.includes("loyalty.");
              let isAvailablePoint = field.matched_key?.includes("available_points.");
              let field_name =
                field.matched_key?.split(".").length == 2
                  ? field.matched_key?.split(".")[1]
                  : field.matched_key?.split(":").length == 2
                  ? field.matched_key?.split(":")[1]
                  : field.matched_key;
              return {
                ...field,
                is_extra: isExtra,
                is_loyalty: isLoyalty,
                is_available_point: isAvailablePoint,
                merchant_type: merchantType,
                field_name: field_name,
              };
            }),
            "order"
          );
        });

        this.detail_fields = groups;
      }


      // 排序 detail fields
      let highlightSection = this.getModuleConfig("highlight_section");

      if (this.getModuleConfig("highlight_section.enable") === true && this.getModuleConfig("highlight_section.fields")) {
        let groups = highlightSection.fields.filter((field) => { 
          return Array.isArray(field);
        });

        groups = groups.map((fields) => {
          //let fields = Array.isArray(group[0]) ? group : [group];

          return _.sortBy(
            fields.map((field) => {
              let merchantType =
                field.matched_key?.split(":").length == 2
                  ? field.matched_key.split(":")[0].toUpperCase()
                  : false;
              let isExtra = field.matched_key?.includes("extra.");
              let isLoyalty = field.matched_key?.includes("loyalty.");
              let isAvailablePoint = field.matched_key?.includes("available_points.");
              let field_name =
                field.matched_key?.split(".").length == 2
                  ? field.matched_key?.split(".")[1]
                  : field.matched_key?.split(":").length == 2
                  ? field.matched_key?.split(":")[1]
                  : field.matched_key;
              return {
                ...field,
                is_extra: isExtra,
                is_loyalty: isLoyalty,
                is_available_point: isAvailablePoint,
                merchant_type: merchantType,
                field_name: field_name,
              };
            }),
            "order"
          );
        });

        this.highlight_fields = groups;
      }

      this.isSectionLoading = false;
    },
    async getMerchants() {
      const data = await merchantApi.list();
      this.merchants = data.data;
    },
    generateBarcode() {
      let canvasElement = document.createElement("canvas");

      try {
        bwipjs.toCanvas(canvasElement, {
          bcid: "code128", // Barcode type
          text: this.modifiedUser.customer_number,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center", // Always good to set this
        });
        //document.getElementById("card__barcode-img").src =
        this.barcode = canvasElement.toDataURL("image/png");
        this.showBarcodeError = false;
      } catch (e) {
        console.log("barcode generate error", e);
        this.showBarcodeError = true;
      }
    },
    handleEdit() {
      this.$router.push({ name: "LiffMemberCenterEdit" });
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("member_center", key);
    },
    getIcon(path) {
      try {
        return requireImage(path);
      } catch {
        return "";
      }
    },
    getIconMask(i) {
      return `url(${
        i.icon_url == undefined ? this.getIcon(`${i.icon_type || i.type}.svg`) : i.icon_url
      })`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style.scss";

.spinner-border {
  border-width: 0.2rem;
}

.barcode-modal {
  ::v-deep .popup__header {
    margin-bottom: 0px;
  }

  ::v-deep .popup__panel {
    background-color: transparent;

    padding: 0;

    box-shadow: none;
  }

  ::v-deep .popup__body.scrolling {
    height: auto;
  }

  .card {
    background: white;
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.info {
  border-radius: 10px;
  border: solid 1px #e5e5ea;
  background: white;

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__desc {
    font-size: 16px;
  }

  &__content {
    border-top: solid 1px #e5e5ea;
    font-size: 14px;
    padding: 16px;
  }
}
</style>
