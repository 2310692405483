<template>
  <div class="s-layout" :style="colorObject">
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />
    <div class="s-container">
      <div v-if="step == 1">
        <img
          src="/familyMart/start.png"
          class="w-100 img-fluid"
          @click="init"
        />
      </div>
      <div v-else-if="step == 2">
        <div class="py-2">
          <div class="p-2">score:{{ score }}</div>
          <canvas
            ref="gameCanvas"
            id="gameCanvas"
            :width="width"
            :height="width"
          ></canvas>
        </div>

        <div class="controls">
          <button
            class="controls__btn controls__up"
            @click="
              () => {
                if (d !== 'DOWN') d = 'UP';
              }
            "
          >
            ↑</button
          ><br />
          <button
            class="controls__btn controls__left"
            @click="
              () => {
                if (d !== 'RIGHT') d = 'LEFT';
              }
            "
          >
            ←
          </button>
          <button
            class="controls__btn controls__right"
            @click="
              () => {
                if (d !== 'LEFT') d = 'RIGHT';
              }
            "
          >
            →
          </button>
          <button
            class="controls__btn controls__down"
            @click="
              () => {
                if (d !== 'UP') d = 'DOWN';
              }
            "
          >
            ↓
          </button>
        </div>

        <!-- <Header
          :page-title="getModuleConfig('page_title')"
          :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')"
          :header-section="getModuleConfig('header_section')"
          :meta="meta"
        /> -->
      </div>

      <b-modal
        id="modal-result"
        centered
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
      >
        <div :style="colorObject">
          <p class="my-4">分數：{{ score }}</p>

          <SharedButton @click="init" variant="primary" class="mb-2">
            再玩一次
          </SharedButton>
          <SharedButton variant="primary"  class="mb-2"> 分享好友 </SharedButton>

          <MemberButton
            variant="primary"
            button-text="回LINE官方帳號"
            type="OA"
          >
          </MemberButton>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
// Packages
import { mapGetters } from "vuex";
// Waltily Utils
import { themeColorFn } from "@/mixins/liff/themeColor";
// Components
import Loading from "@/components/Page/Liff/Shared/Loading";

let gridCount = 20;
let box = 0;
let canvas = null;
let context = null;
let game = null;
let food = [];
let words = ["滿三百折三十", "加十元多一件", "滿六八八贈好禮"];

let snake = [{ x: 9 * box, y: 10 * box }];
const snakeHeadImages = {
  UP: new Image(),
  DOWN: new Image(),
  LEFT: new Image(),
  RIGHT: new Image(),
};

export default {
  mixins: [themeColorFn({ themeConfigPage: "redeem" })],
  components: {
    Loading,
    MemberButton,
    SharedButton,
  },
  mounted() {
    let vw =
      Math.floor(
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ) / 10
      ) * 10;
    vw = (vw > 768 ? 768 : vw) - 20;
    this.width = Math.floor(vw / 10) * 10;

    console.log(this.width);

    box = this.width / gridCount;

    snakeHeadImages["UP"].src = require("./images/snake-head-up.png");
    snakeHeadImages["DOWN"].src = require("./images/snake-head-down.png");
    snakeHeadImages["LEFT"].src = require("./images/snake-head-left.png");
    snakeHeadImages["RIGHT"].src = require("./images/snake-head-right.png");

    document.addEventListener("keydown", this.direction);
  },
  data() {
    return {
      isLoading: false,
      score: 0,
      d: "RIGHT",
      width: 0,
      step: 1,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
  },

  methods: {
    init: async function () {

      this.$bvModal.hide("modal-result");

      this.step = 2;

      await this.$nextTick();

      canvas = this.$refs.gameCanvas;
      context = canvas.getContext("2d");

      clearInterval(game);

      snake = [];
      food = [];
      snake[0] = { x: 9 * box, y: 10 * box };
      this.score = 0;
      this.d = "RIGHT";

      this.generateFood();
      let speed = this.$route.query.speed || 300;

      game = setInterval(this.draw, speed);
    },
    generateFood: function () {
      // 獲取所有可能的位置
        const availablePositions = [];
        for (let x = 0; x < gridCount; x++) {
          for (let y = 0; y < gridCount; y++) {
            const pos = { x: x * box, y: y * box };
            // 確保位置不在蛇的身體內
            if (!this.isCollision(pos.x, pos.y)) {
              availablePositions.push(pos);
            }
          }
        }

        // 如果沒有可用位置，直接結束（極端情況）
        if (availablePositions.length === 0) {
          console.warn("沒有可用位置生成食物！");
          return;
        }

        // 隨機選擇文字
        const word = words[Math.floor(Math.random() * words.length)];
        const numberOfFoods = word.length;

        let foodPosition;
        let randomOrientation;
        canvas = this.$refs.gameCanvas;

        // 嘗試生成食物，直到找到合法的位置和方向
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // 隨機選擇一個位置
          const randomIndex = Math.floor(Math.random() * availablePositions.length);
          foodPosition = availablePositions[randomIndex];

          // 隨機選擇方向
          randomOrientation = Math.random() < 0.5 ? "row" : "column";

          // 檢查是否在畫布內
          const fitsInCanvas =
            randomOrientation === "row"
              ? foodPosition.x + (numberOfFoods - 1) * box < canvas.width
              : foodPosition.y + (numberOfFoods - 1) * box < canvas.height;

          // 確保文字排列不超出畫布
          if (fitsInCanvas) break;
        }

        // 根據方向生成文字
        for (let i = 0; i < numberOfFoods; i++) {
          if (randomOrientation === "row") {
            food.push({
              x: foodPosition.x + i * box,
              y: foodPosition.y,
              text: word[i],
            });
          } else {
            food.push({
              x: foodPosition.x,
              y: foodPosition.y + i * box,
              text: word[i],
            });
          }
        }
    },
    isCollision: function (x, y) {
      for (let i = 0; i < snake.length; i++) {
        if (snake[i].x === x && snake[i].y === y) {
          return true;
        }
      }
      return false;
    },
    collision: function (head, array) {
      for (let i = 0; i < array.length; i++) {
        if (head.x === array[i].x && head.y === array[i].y) {
          return true;
        }
      }
      return false;
    },

    drawGrid: function () {
      for (let y = 0; y < gridCount; y++) {
        for (let x = 0; x < gridCount; x++) {
          context.fillStyle = (x + y) % 2 === 0 ? "#aad751" : "#a2d149";
          // 交錯顏色
          context.fillRect(x * box, y * box, box, box);
        }
      }
    },

    draw: function () {
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.drawGrid();

      for (let i = 0; i < snake.length; i++) {
        if (i === 0) {
          // 繪製蛇頭
          context.drawImage(
            snakeHeadImages[this.d],
            snake[i].x,
            snake[i].y,
            box,
            box
          );
        } else {
          context.fillStyle = "#3bab22";
          context.fillRect(snake[i].x, snake[i].y, box, box);
          // context.strokeStyle = "red";
          // context.strokeRect(snake[i].x, snake[i].y, box, box);
        }
      }

      for (let i = 0; i < food.length; i++) {
        context.fillStyle = "red";
        context.fillRect(food[i].x, food[i].y, box, box);
        context.fillStyle = "black";
        context.font = `${box}px Arial`;
        context.fillText(food[i].text, food[i].x, food[i].y + box);
      }

      let snakeX = snake[0].x;
      let snakeY = snake[0].y;

      if (this.d === "LEFT") snakeX -= box;
      if (this.d === "UP") snakeY -= box;
      if (this.d === "RIGHT") snakeX += box;
      if (this.d === "DOWN") snakeY += box;

      let ateFood = false;
      for (let i = 0; i < food.length; i++) {
        if (snakeX === food[i].x && snakeY === food[i].y) {
          this.score++;
          food.splice(i, 1);
          ateFood = true;
        }
      }

      let newHead = { x: snakeX, y: snakeY };

      if (
        snakeX < 0 ||
        snakeX >= canvas.width ||
        snakeY < 0 ||
        snakeY >= canvas.height ||
        this.collision(newHead, snake)
      ) {
        this.gameover();
      }

      snake.unshift(newHead);
      if (!ateFood) {
        snake.pop(); // 只有當沒有吃到食物時才移除尾巴
      }

      if (food.length === 0) {
        this.generateFood(); // 當所有食物都被吃掉後生成新食物
      }
    },
    direction: function (event) {
      if (event.keyCode === 37 && this.d !== "RIGHT") {
        this.d = "LEFT";
      } else if (event.keyCode === 38 && this.d !== "DOWN") {
        this.d = "UP";
      } else if (event.keyCode === 39 && this.d !== "LEFT") {
        this.d = "RIGHT";
      } else if (event.keyCode === 40 && this.d !== "UP") {
        this.d = "DOWN";
      }
    },
    gameover: function () {
      clearInterval(game);

      this.$bvModal.show("modal-result");
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/css/liff/main.css";
@import "../../../../assets/css/liff/liff_init_theme_config.css";
@import "../../../../assets/scss/shared/components/_fields.scss";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
  position: relative;
  max-width: 768px;
  margin: auto;
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}
.content {
  height: calc(100vh - 56px);
  overflow-y: auto;
  margin: 0 24px;
  padding-bottom: 24px;
}

canvas {
  display: block;
  margin: auto !important;
  background-color: #f0f0f0;
}

.controls {
  text-align: center;
  margin: auto;
  margin-top: 10px;
  width: 200px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 10px;
  grid-template-areas:
    "up up up"
    "left . right"
    "down down down"
    "reset reset reset";

  &__btn {
    font-size: 20px;
    border: solid 1px black;
    min-height: 50px;
  }

  &__reset {
    grid-area: reset;
  }
  &__up {
    grid-area: up;
  }
  &__down {
    grid-area: down;
  }
  &__left {
    grid-area: left;
  }
  &__right {
    grid-area: right;
  }
}

.modal-backdrop {
  opacity: 0.5;
}
</style>
