<template>
  <b-form-group
    label-cols="12"
    label-cols-lg="2"
    label-size="sm"
    :label="subject.title"
    :label-for="subject.id"
  >
    <b-form-select
      :id="subject.id"
      v-model="selected"
      :state="validateError ? false : null"
      :options="options"
      :disabled="subject.disabled"
    ></b-form-select>
    <small v-if="subject.memo" class="form-text text-muted">
      {{ subject.memo }}
    </small>
    <b-form-invalid-feedback :state="!validateError">
      輸入的資料有誤或未填寫，請確認
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import collectionApi from "@/apis/collection";
import Collection from "@/models/Collection.model.js";
import _ from "lodash";

export default {
  props: {
    subject: {
      type: Object,
      required: true,
    },
    // value: [String, Number, Array, Object],
    value: {
      type: Array,
      default: () => {[]},
    },
    validateError: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '請選擇',
    },
    branchId: {
      type: String,
      required: false,
    },
    isRelatedToBranch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collection: null,
    }
  },
  watch: {
    subject: {
      handler: function (val) {
        console.log(val);
        if (val.collection_id) {
          this.fetchCollectionItems();
        }
      },
      immediate: true,
    },
  },
  computed: {
    selected: {
      get() {
        // NOTE: parse array value to a single value, also treat undefined as null, to make placeholder work as expected
        if (Array.isArray(this.value)) {
          return this.value[0] === undefined ? null : this.value[0];
        }
        return this.value === undefined ? null : this.value;
      },
      set(v) {
        if (v !== null) {
          this.$emit("input", Array.isArray(v) ? v : [v]);
        } else {
          this.$emit("input", null);
        }
      }
    },
    options() {
      if (this.collection === null) {
        return [{ text: this.placeholder, value: null }]
      }

      const collectionModel = new Collection(this.collection)

      return [
        { text: this.placeholder, value: null },
        ..._.get(this.collection, 'items', []).sort((a, b) => a.order - b.order).map((item) => {
          return {
            value: {
              type: 'collection_select',
              collection_item_id: item.id,
              collection_id: this.collection.id,
              text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
            },
            text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
          };
        })
      ];
    },
  },
  methods: {
    async fetchCollectionItems() {
      try {
        if (this.isRelatedToBranch) {
          if (this.branchId) {
            const response = await collectionApi.getOptionsForBranch(this.subject.collection_id, this.branchId);
            this.collection = response.data.data;
          } else {
            this.collection = null
          }
        } else {
          const response = await collectionApi.getCollection(this.subject.collection_id);
          this.collection = response.data.data;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${this.subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error");
      }
    }
  }
}
</script>
