<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            內容管理中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'FormList' }">
            表單列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯表單' : isReadOnly ? '檢視表單' : '新增表單'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '', done: form.id }" @click="goToStep('')">
                <a>基本設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#welcome', done: deepGet(form, 'config.welcome_page.enable', false), disabled: !form.id }" @click="goToStep('#welcome')">
                <a>歡迎頁</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#content', disabled: !form.id }" @click="goToStep('#content')">
                <a>內容頁</a>
              </li>
              <li :class="{ active: step == '#questionnaire', done: deepGet(form, 'subjects.length', 0) > 0, disabled: !form.id }" @click="goToStep('#questionnaire')">
                <a>內容設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#thanks', disabled: !form.id }" @click="goToStep('#thanks')">
                <a>感謝頁</a>
              </li>
              <li v-if="isApply" :class="{ active: step == '#confirm', disabled: !form.id }" @click="goToStep('#confirm')">
                <a>核銷頁</a>
              </li>
              <li :class="{ active: step == '#limit', disabled: !form.id }" @click="goToStep('#limit')">
                <a>上限頁</a>
              </li>
              <li :class="{ active: step == '#advanced-setting', disabled: !form.id }" @click="goToStep('#advanced-setting')">
                <a>進階設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#event-setting', disabled: !form.id }" v-if="isEvent" @click="goToStep('#event-setting')">
                <a>活動設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本設定 -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <h4 class="mb-3 font-weight-bold">{{ isEditing ? '編輯表單' : isReadOnly ? '檢視表單' : '新增表單' }}</h4>
                <div class="row mb-2">
                  <div class="col-12 col-xl-10">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 類型 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 類型"
                      :disabled="isReadOnly"
                    >
                      <b-form-radio-group v-model="form.type">
                        <b-form-radio v-if="checkPermission([consts.FORM_SURVEY_MODE])" value="survey">問卷</b-form-radio>
                        <b-form-radio v-if="checkPermission([consts.FORM_EVENT_MODE])" value="event">活動</b-form-radio>
                        <b-form-radio v-if="checkPermission([consts.FORM_APPLY_MODE])" value="apply">申請</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <!-- 類型 End -->
                    <!-- 狀態 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 狀態"
                      :disabled="isReadOnly"
                    >
                      <b-select
                        :options="[
                          { text: '草稿', value: 'draft' },
                          { text: '公開', value: 'published' },
                          { text: '關閉', value: 'closed' },
                        ]"
                        v-model="form.status"
                      ></b-select>
                    </b-form-group>
                    <!-- 狀態 End -->
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 名稱"
                      :disabled="isReadOnly"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.form.title.$error ? false: null"
                        v-model="form.title"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.form.title.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                    <!-- 填寫權限 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="填寫權限"
                      :disabled="isReadOnly"
                    >
                      <b-form-checkbox-group
                        v-model="form.config.permissions"
                        :options="permissionOptions"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <!-- 填寫次數 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 填寫次數"
                      :disabled="isReadOnly"
                    >
                      <b-form-radio-group
                        :options="[
                            { text: '不限定', value: 'unlimit' },
                            { text: '限定次數', value: 'limit' },
                          ]"
                        v-model="fillOutSetting"
                      >
                        <b-form-input class="w-25" type="number" min="0" v-model="form.fill_out_times" :disabled="fillOutSetting === 'unlimit'"></b-form-input>
                      </b-form-radio-group>
                    </b-form-group>

                    <!-- 達上限次數後，顯示 Start -->
                    <b-form-group
                      v-if="fillOutSetting === 'limit'"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="達上限次數後，顯示"
                      :disabled="isReadOnly"
                    >
                      <b-form-radio-group
                        :options="[
                          { text: '歡迎頁', value: 'welcome_page' },
                          { text: '感謝頁', value: 'thanks_page' },
                          { text: '上限頁', value: 'limit_page' },
                        ]"
                        v-model="form.config.basic_setting.when_reach_limit_show"
                      >
                        <b-form-radio v-if="fillOutSetting == 'limit' && form.type === 'apply'" value="confirm_page">核銷頁(最新一筆)</b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="!v$.form.config.basic_setting.when_reach_limit_show.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 填寫後顯示 Start -->
                    <b-form-group
                      v-if="form.type === 'apply'"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 填寫後顯示"
                      :disabled="isReadOnly"
                    >
                      <b-form-radio-group
                        :options="[
                          { text: '感謝頁', value: 'thanks_page' },
                          { text: '核銷頁', value: 'confirm_page' },
                        ]"
                        v-model="form.config.basic_setting.after_fill_out_show"
                      >
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="!v$.form.config.basic_setting.after_fill_out_show.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 表單開始時間 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 開始時間"
                      :disabled="isReadOnly"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="選擇日期"
                            v-model="start_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                          ></datepicker>
                          <b-form-invalid-feedback
                            :state="!v$.start_at.date.required.$error"
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            placeholder="選擇時間"
                            format="HH:mm"
                            v-model="start_at.time"
                            :input-class="['form-control']"
                          ></vue-timepicker>
                          <b-form-invalid-feedback
                            :state="
                              !v$.start_at.time.HH.$error ||
                              !v$.start_at.time.mm.$error
                            "
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 表單開始時間 End -->
                    <!-- 表單結束時間 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 結束時間"
                      :disabled="isReadOnly"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="選擇日期"
                            v-model="end_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.start_at.$invalid ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.end_at.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            :state="!v$.start_at.date.beforeEndAt.$invalid"
                          >
                            結束時間必須大於起始時間
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            placeholder="選擇時間"
                            :input-class="[
                              'form-control',
                              {
                                'is-invalid':
                                  v$.start_at.time.HH.$error ||
                                  v$.start_at.time.mm.$error ||
                                  v$.start_at.date.beforeEndAt.$invalid,
                              },
                            ]"
                            v-model="end_at.time"
                          ></vue-timepicker>
                          <b-form-invalid-feedback
                            :state="
                              !v$.end_at.time.HH.$error || !v$.end_at.time.mm.$error
                            "
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 表單結束時間 End -->
                    <!-- 報到開始時間 Start -->
                    <b-form-group
                      v-if="isEvent"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 報到開始時間"
                      :disabled="isReadOnly"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="選擇日期"
                            v-model="checkinStartAt.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                          ></datepicker>
                          <b-form-invalid-feedback
                            :state="!v$.checkinStartAt.date.required.$error"
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            placeholder="選擇時間"
                            format="HH:mm"
                            v-model="checkinStartAt.time"
                            :input-class="['form-control']"
                          ></vue-timepicker>
                          <b-form-invalid-feedback
                            :state="
                              !v$.checkinStartAt.time.HH.$error ||
                              !v$.checkinStartAt.time.mm.$error
                            "
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 報到報到開始時間 End -->
                    <!-- 報到報到結束時間 Start -->
                    <b-form-group
                      v-if="isEvent"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 報到結束時間"
                      :disabled="isReadOnly"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="選擇日期"
                            v-model="checkinEndAt.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.checkinStartAt.$invalid ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.checkinEndAt.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            :state="!v$.checkinStartAt.date.beforeEndAt.$invalid"
                          >
                            結束時間必須大於起始時間
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            placeholder="選擇時間"
                            :input-class="[
                              'form-control',
                              {
                                'is-invalid':
                                  v$.checkinStartAt.time.HH.$error ||
                                  v$.checkinStartAt.time.mm.$error ||
                                  v$.checkinStartAt.date.beforeEndAt.$invalid,
                              },
                            ]"
                            v-model="checkinEndAt.time"
                          ></vue-timepicker>
                          <b-form-invalid-feedback
                            :state="
                              !v$.checkinEndAt.time.HH.$error || !v$.checkinEndAt.time.mm.$error
                            "
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 報到報到結束時間 End -->
                    <!-- 重複填寫設定 Start -->
                    <b-form-group
                      v-if="featureToggles.form_repeat_submit"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 重複填寫設定"
                      :disabled="isReadOnly"
                    >
                      <b-form-radio-group
                        v-model="form.config.repeat_submit"
                        :options="[
                          { text: '限填寫一次', value: 'once' },
                          { text: '允許重複新增', value: 'repeat' },
                          { text: '允許重複填寫，但維持一份紀錄', value: 'repeat_one' }
                        ]"
                      >
                      </b-form-radio-group>
                    </b-form-group>
                    <!-- 重複填寫設定 End -->
                    <!-- 瀏覽器紀錄 Start -->
                    <b-form-group
                      v-if="featureToggles.form_remember_in_browser"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="瀏覽器紀錄"
                      description="每個欄位的答案在送出前，都會記錄在瀏覽器，不會因為誤關視窗而消失"
                      :disabled="isReadOnly"
                    >
                      <b-form-checkbox v-model="form.config.remember_in_browser">啟用</b-form-checkbox>
                    </b-form-group>
                    <!-- 瀏覽器紀錄 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center preserve-spacing-for-datetime-picker" v-if="!isReadOnly">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 歡迎頁 -->
              <section v-if="step == '#welcome' && form.config.welcome_page">
                <h4 class="mb-3 font-weight-bold">歡迎頁</h4>
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                <!-- 啟用 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="啟用"
                  :disabled="isReadOnly"
                >
                  <b-form-checkbox v-model="form.config.welcome_page.enable">啟用</b-form-checkbox>
                </b-form-group>
                <!-- 啟用 End -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  content-cols="12"
                  content-cols-lg="6"
                  :disabled="isReadOnly"
                >
                  <template #label>
                    Banner
                    <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 700px</p>
                  </template>
                  <template #default>
                    <li
                      class="s-image-list-item s-draggable"
                      v-if="selectedImages['welcome_banner_image']"
                    >
                      <img class="s-cropped-image" :src="selectedImages['welcome_banner_image']" />
                      <button
                        class="btn btn-danger s-delete-btn"
                        type="button"
                        @click="selectImage(null, 'welcome_banner_image')"
                      >
                        Ｘ
                      </button>
                    </li>
                    <li class="s-image-list-item" v-else>
                      <ImageSelector :aspect-ratio="1400 / 700" @input="(croppedImage) => selectImage(croppedImage, 'welcome_banner_image')">
                        <template #trigger="{ openFileSelector }">
                          <button class="btn s-trigger-btn" @click="openFileSelector" :disabled="!form.config.welcome_page.enable">
                            ＋ 加入照片
                          </button>
                        </template>
                      </ImageSelector>
                    </li>
                  </template>
                </b-form-group>
                <!-- 標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="標題"
                  :disabled="isReadOnly"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :disabled="!form.config.welcome_page.enable"
                    :state="deepGet(v$, 'form.config.welcome_page.form_title.$error') ? false : null"
                    v-model="form.config.welcome_page.form_title"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.welcome_page.form_title.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 標題 End -->
                <!-- 副標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="副標題"
                  :disabled="isReadOnly"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :disabled="!form.config.welcome_page.enable"
                    :state="deepGet(v$, 'form.config.welcome_page.form_desc.$error') ? false : null"
                    v-model="form.config.welcome_page.form_desc"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.welcome_page.form_desc.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 副標題 End -->
                <!-- 內容說明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="內容說明"
                  :disabled="isReadOnly"
                >
                  <TrixEditor :readonly="!form.config.welcome_page.enable || isReadOnly" v-model="form.config.welcome_page.content" />
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.welcome_page.content.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 內容說明 End -->
                <!-- 按鈕文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="按鈕文字"
                  :disabled="isReadOnly"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :disabled="!form.config.welcome_page.enable"
                    :state="deepGet(v$, 'form.config.welcome_page.button_text.$error') ? false: null"
                    v-model="form.config.welcome_page.button_text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.welcome_page.button_text.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 按鈕文字 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center" v-if="!isReadOnly">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submitWelcomePage"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 內容頁 -->
              <section v-if="step == '#content' && form.config.content_page">
                <h4 class="mb-3 font-weight-bold">內容頁</h4>
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  content-cols="12"
                  content-cols-lg="6"
                  :disabled="isReadOnly"
                >
                  <template #label>
                    Banner
                    <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 700px</p>
                  </template>
                  <template #default>
                    <li
                      class="s-image-list-item s-draggable"
                      v-if="selectedImages['content_banner_image']"
                    >
                      <img class="s-cropped-image" :src="selectedImages['content_banner_image']" />
                      <button
                        class="btn btn-danger s-delete-btn"
                        type="button"
                        @click="selectImage(null, 'content_banner_image')"
                      >
                        Ｘ
                      </button>
                    </li>
                    <li class="s-image-list-item" v-else>
                      <ImageSelector :aspect-ratio="1400 / 700" @input="(croppedImage) => selectImage(croppedImage, 'content_banner_image')">
                        <template #trigger="{ openFileSelector }">
                          <button class="btn s-trigger-btn" @click="openFileSelector">
                            ＋ 加入照片
                          </button>
                        </template>
                      </ImageSelector>
                    </li>
                  </template>
                </b-form-group>
                <!-- 標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="標題"
                  :disabled="isReadOnly"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="deepGet(v$, 'form.config.content_page.form_title.$error') ? false : null"
                    v-model="form.config.content_page.form_title"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.content_page.form_title.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 標題 End -->
                <!-- 副標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="副標題"
                  :disabled="isReadOnly"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="deepGet(v$, 'form.config.content_page.form_desc.$error') ? false : null"
                    v-model="form.config.content_page.form_desc"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.content_page.form_desc.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 副標題 End -->
                <!-- 內容說明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="內容說明"
                  :disabled="isReadOnly"
                >
                  <TrixEditor v-model="form.config.content_page.content" :readonly="isReadOnly"/>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.content_page.content.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 內容說明 End -->
                <!-- 按鈕文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="按鈕文字"
                  :disabled="isReadOnly"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="deepGet(v$, 'form.config.content_page.button_text.$error') ? false : null"
                    v-model="form.config.content_page.button_text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!deepGet(v$, 'form.config.content_page.button_text.$error')">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- 按鈕文字 End -->
                <!-- 使用條款與聲明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="使用條款與聲明"
                  :disabled="isReadOnly"
                >
                  <ckeditor
                    :editor="editor"
                    v-model="form.terms"
                    tag-name="textarea"
                    :disabled="isReadOnly"
                  ></ckeditor>
                </b-form-group>
                <!-- 使用條款與聲明 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center" v-if="!isReadOnly">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submitContentPage"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 內容設定 -->
              <section v-if="step == '#questionnaire'">
                <DynamicFormEditor
                  v-if="form.id"
                  @output="saveSubjects($event)"
                  @cancel="$router.push({ name: 'FormList' })"
                  editor-title="內容設定"
                  :input-subjects="form.subjects"
                  :readonly="isReadOnly"
                  :form-type="'survey'"
                  :validate-subject="validSubject"
                  validation-message="標題不得為空"
                ></DynamicFormEditor>
              </section>

              <!-- 感謝頁 -->
              <section v-if="step == '#thanks' && form.config.thanks_page">
                <ThanksPage
                  @loading="showLoading = $event"
                  @submit="submit"
                  @cancel="cancel"
                  :form="form"
                  :imageUrl="selectedImages['thanks_banner_image']"
                  :readonly="isReadOnly"
                ></ThanksPage>
              </section>

              <!-- 核銷頁 -->
              <section v-if="step == '#confirm' && form.config.confirm_page">
                <ConfirmPage
                  @loading="showLoading = $event"
                  @submit="submit"
                  @cancel="cancel"
                  :form="form"
                  :imageUrl="selectedImages['confirm_banner_image']"
                  :readonly="isReadOnly"
                ></ConfirmPage>
              </section>

              <!-- 上限頁 -->
              <section v-if="step == '#limit' && form.config.limit_page">
                <LimitPage
                  @loading="showLoading = $event"
                  @submit="submit"
                  @cancel="cancel"
                  :form="form"
                  :imageUrl="selectedImages['limit_banner_image']"
                  :readonly="isReadOnly"
                ></LimitPage>
              </section>

              <!-- 進階設定 -->
              <section v-if="step == '#advanced-setting' && form.config.advanced_setting">
                <h4 class="mb-3 font-weight-bold">進階設定</h4>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="填寫後立即推播"
                  :disabled="isReadOnly"
                >
                  <b-form-input placeholder="請輸入Template Code" v-model="form.config.advanced_setting.notify_after_fill_out_template_code"></b-form-input>
                  <b-form-checkbox v-model="form.config.advanced_setting.notify_after_fill_out" :disabled="!form.config.advanced_setting.notify_after_fill_out_template_code">啟用</b-form-checkbox>
                </b-form-group>
                <!-- 填寫後貼標 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="填寫後貼標"
                  :disabled="isReadOnly"
                >
                  <TagsBox v-model="form.config.advanced_setting.finish_tags"></TagsBox>
                </b-form-group>
                <!-- 填寫後貼標 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center" v-if="!isReadOnly">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submitAdvancedSetting"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 事件設定 -->
              <section v-if="step == '#event-setting'">
                <h4 class="mb-3 font-weight-bold">活動設定</h4>
                <!-- 類型 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="是否需審核"
                  :disabled="isReadOnly"
                >
                  <b-form-radio-group
                    :options="[
                      { text: '需審核', value: true },
                      { text: '不審核(報名後立即通過)', value: false },
                    ]"
                    v-model="form.config.event_setting.enable_verify"
                  >
                  </b-form-radio-group>
                </b-form-group>
                <!-- 類型 End -->
                <!-- 審核通過通知 Start -->
                <!-- <b-form-group -->
                <!--   label-cols="12" -->
                <!--   label-cols-lg="3" -->
                <!--   label-size="sm" -->
                <!--   label="審核通過通知" -->
                <!--   :disabled="isReadOnly" -->
                <!-- > -->
                <!--   <b-form-checkbox-group -->
                <!--     v-model="form.config.event_setting.approved_notify" -->
                <!--     :options="[{ text: 'Line 推播', value: 'line' }, { text: 'Email', value: 'email' }]" -->
                <!--   ></b-form-checkbox-group> -->
                <!-- </b-form-group> -->
                <!-- 審核通過通知 End -->
                <!-- 審核通過通知內容 Start -->
                <!-- <b-form-group -->
                <!--   label-cols="12" -->
                <!--   label-cols-lg="3" -->
                <!--   label-size="sm" -->
                <!--   label="審核通過通知內容" -->
                <!-- > -->
                <!--   <b-textarea v-model="form.config.event_setting.approved_notify_content"></b-textarea> -->
                <!-- </b-form-group> -->
                <!-- 審核通過通知內容 End -->
                <!-- 審核通過貼標 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="審核通過貼標"
                  :disabled="isReadOnly"
                >
                  <TagsBox v-model="form.config.event_setting.approved_tags"></TagsBox>
                </b-form-group>
                <!-- 審核通過貼標 End -->
                <!-- 審核不通過通知 Start -->
                <!-- <b-form-group -->
                <!--   label-cols="12" -->
                <!--   label-cols-lg="3" -->
                <!--   label-size="sm" -->
                <!--   label="審核不通過通知" -->
                <!--   :disabled="isReadOnly" -->
                <!-- > -->
                <!--   <b-form-checkbox-group -->
                <!--     v-model="form.config.event_setting.rejected_notify" -->
                <!--     :options="[{ text: 'Line 推播', value: 'line' }, { text: 'Email', value: 'email' }]" -->
                <!--     :disabled="false == form.config.event_setting.enable_verify" -->
                <!--   ></b-form-checkbox-group> -->
                <!-- </b-form-group> -->
                <!-- 審核不通過通知 End -->
                <!-- 審核不通過通知內容 Start -->
                <!-- <b-form-group -->
                <!--   label-cols="12" -->
                <!--   label-cols-lg="3" -->
                <!--   label-size="sm" -->
                <!--   label="審核不通過通知內容" -->
                <!--   :disabled="isReadOnly" -->
                <!-- > -->
                <!--   <b-textarea v-model="form.config.event_setting.rejected_notify_content" :disabled="false == form.config.event_setting.enable_verify"></b-textarea> -->
                <!-- </b-form-group> -->
                <!-- 審核不通過通知內容 End -->
                <!-- 審核不通過貼標 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="審核不通過貼標"
                >
                  <TagsBox v-model="form.config.event_setting.rejected_tags" :disabled="false == form.config.event_setting.enable_verify"></TagsBox>
                </b-form-group>
                <!-- 審核不通過貼標 End -->
                <!-- 成功報到貼標 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="成功報到(事先報名)貼標"
                  :disabled="isReadOnly"
                >
                  <TagsBox v-model="form.config.event_setting.attend_tags"></TagsBox>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="現場報到(未事先報名)貼標"
                  :disabled="isReadOnly"
                >
                  <TagsBox v-model="form.config.event_setting.onsite_checkin_tags"></TagsBox>
                </b-form-group>
                <!-- 成功報到貼標 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center" v-if="!isReadOnly">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submitEventSetting"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import {
  format,
  isBefore,
  set,
  getHours,
  getMinutes,
} from "date-fns";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import formApi from "@/apis/form";
import DynamicFormEditor from "@/components/DynamicFormEditor/DynamicFormEditor.vue";
import ImageSelector from "@/components/ImageSelector";
import TagsBox from "@/components/TagsBox";
import TrixEditor from "@/components/TrixEditor";
import ThanksPage from "@/components/Dashboard/Form/ThanksPage";
import ConfirmPage from "@/components/Dashboard/Form/ConfirmPage";
import LimitPage from "@/components/Dashboard/Form/LimitPage";
import deepGet from "@/utils/deepGet";
import imageMixin from "@/mixins/uploadImage";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from '@/consts'

export default {
  components: {
    Datepicker,
    VueTimepicker,
    DynamicFormEditor,
    ImageSelector,
    TagsBox,
    TrixEditor,
    ThanksPage,
    ConfirmPage,
    LimitPage,
  },
  mixins: [imageMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        title: { required },
        config: {
          basic_setting: {
            when_reach_limit_show: { required: requiredIf(() => this.fillOutSetting === 'limit') },
            after_fill_out_show: { required: requiredIf(() => this.form.type === 'apply') },
          },
          advanced_setting: {
            notify_after_fill_out: { required: requiredIf(() => this.form.config.advanced_setting.notify_after_fill_out_template_code) },
          }
        },
      },
      fillOutSetting: { required },
      start_at: {
        date: {
          required,
          beforeEndAt: () => {
            if (!this.end_at.date || !this.end_at.time.HH || !this.end_at.time.mm) {
              return true
            }

            const startDate = set(new Date(this.start_at.date), {
              hours: this.start_at.time.HH,
              minutes: this.start_at.time.mm,
            });

            const endDate = set(new Date(this.end_at.date), {
              hours: this.end_at.time.HH,
              minutes: this.end_at.time.mm,
            });

            return isBefore(startDate, endDate);
          },
        },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      end_at: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      checkinStartAt: {
        date: {
          required: requiredIf(() => this.isEvent),
          beforeEndAt: () => {
            if (!this.checkinEndAt.date || !this.checkinEndAt.time.HH || !this.checkinEndAt.time.mm) {
              return true
            }

            const startDate = set(new Date(this.checkinStartAt.date), {
              hours: this.checkinStartAt.time.HH,
              minutes: this.checkinStartAt.time.mm,
            });

            const endDate = set(new Date(this.checkinEndAt.date), {
              hours: this.checkinEndAt.time.HH,
              minutes: this.checkinEndAt.time.mm,
            });

            return isBefore(startDate, endDate);
          },
        },
        time: {
          HH: { required: requiredIf(() => this.isEvent) },
          mm: { required: requiredIf(() => this.isEvent) },
        },
      },
      checkinEndAt: {
        date: { required: requiredIf(() => this.isEvent) },
        time: {
          HH: { required: requiredIf(() => this.isEvent) },
          mm: { required: requiredIf(() => this.isEvent) },
        },
      },
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    permissionOptions() {
      const options = [
        { text: "需註冊", value: "registered" },
      ];
      if(this.featureToggles.field_phone_verified) {
        options.push({ text: "手機驗證", value: "phone_verified" });
      }
      return options;
    },
    isEvent() {
      return this.form.type === 'event';
    },
    isApply() {
      return this.form.type === 'apply';
    },
    isReadOnly() {
      return this.$route.name === 'FormRead';
    },
  },
  data() {
    return {
      zh,
      consts: consts,
      editor: ClassicEditor,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: "",
      start_at: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      end_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      checkinStartAt: {
        time: {
          HH: "00",
          mm: "00",
        },
      },
      checkinEndAt: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      fillOutSetting: 'unlimit',
      form: {
        type: 'survey',
        title: null,
        fill_out_times: 'unlimit',
        start_at: null,
        end_at: null,
        status: 'draft',
        config: {
          permissions: [],
          repeat_submit: 'once',
          remember_in_browser: false,
          basic_setting: {},
          advanced_setting: {},
        },
        terms: '',
        subjects: [],
      },
      formChanged: false,
      selectedImages: {},
      featureToggles: {
        form_repeat_submit: false,
        form_remember_in_browser: false,
        field_phone_verified: false,
      },
      validSubject: false,
    }
  },
  watch: {
    form: {
      handler() {
        this.formChanged = true;
      },
      deep: true,
    },
    start_at: {
      handler() {
        this.formChanged = true;
      },
      deep: true,
    },
    end_at: {
      handler() {
        this.formChanged = true;
      },
      deep: true,
    },
    checkinStartAt: {
      handler() {
        this.formChanged = true;
      },
      deep: true,
    },
    checkinEndAt: {
      handler() {
        this.formChanged = true;
      },
      deep: true,
    },
    "form.fill_out_times"(value) {
      if (value < 0) {
        this.form.fill_out_times = 0
      }
    },
    fillOutSetting: {
      handler(val) {
        if (val === 'unlimit') {
          this.form.fill_out_times = null
        }
      }
    }
  },
  async mounted() {
    this.isEditing = this.$route.name === "FormEdit"
    const isReadPage = this.$route.name === "FormRead"

    if (this.isEditing || isReadPage) {
      this.form.id = this.$route.params.id;
      this.navigateToStepFromCurrentRoute();

      await this.fetchForm();

      if (this.$route.hash == '#event-setting' && this.form.type != 'event') {
        this.goToStep('');
      }
    }

    this.prepareBasicSetting();
    this.prepareDataForWelcomePage();
    this.prepareDataForContentPage();
    this.prepareDataForThanksPage();
    this.prepareDataForConfirmPage();
    this.prepareDataForLimitPage();
    this.prepareDataForAdvancedSetting();
    this.prepareDataForEventSetting();

    // 因為 checkinStartAt, checkinEndAt 會受到 v-if 的影響，所以要等到畫面被渲染完後再設定值
    // 但是要兩層才會有效過，目前原因不明
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.formChanged = false;
      })
    });
  },
  methods: {
    deepGet,
    cancel() {
      this.$router.push({ name: 'FormList', query: this.routeQuery });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async goToStep(step) {
      if (!this.form.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      // if (this.formChanged) {
      //   const result = await this.$swal.fire({
      //     title: "您有尚未儲存的資料，是否需儲存?",
      //     confirmButtonText: "儲存",
      //     cancelButtonText: "不儲存",
      //     showCancelButton: true,
      //     reverseButtons: true,
      //   })

      //   if (result.value) {
      //     const resp = await this.submit()

      //     if (!resp) {
      //       return;
      //     }
      //   }
      // }

      this.step = step;
      // if (this.$route.hash !== this.step) {
      //   this.$router.replace({
      //     hash: this.step,
      //     silent: true
      //   });
      // }
      if (location.hash !== this.step) {
        // NOTE: 這裡不使用 $router.replace，以避免 page rerender 導致 mounted 或其他 lifecycle hook 的內的 API 重複執行
        const newLocation = new URL(location.href);
        newLocation.hash = this.step;
        window.history.replaceState("", "", newLocation.href);
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      } else {
        this.goToStep('');
      }
    },
    selectImage(image, key) {
      this.$set(this.selectedImages, key, image);

      if (image == null && key == 'welcome_banner_image') {
        this.$set(this.form.config.welcome_page, 'banner_image', null);
      }
    },
    prepareBasicSetting() { // NOTE: should be called immediately after this.form is reassigned
      if (!this.form.config.basic_setting) {
        this.$set(this.form.config, 'basic_setting', {
          "when_reach_limit_show": null,
          "after_fill_out_show": null,
        });
      }
    },
    prepareDataForWelcomePage() { // NOTE: should be called immediately after this.form is reassigned
      if (!this.form.config.welcome_page) {
        this.$set(this.form.config, 'welcome_page', {
          "enable": false,
          "banner_image": null,
          "form_title": "",
          "form_desc": "",
          "content": "",
          "button_text": "",
        });
      }
    },
    prepareDataForContentPage() { // NOTE: should be called immediately after this.form is reassigned
      if (!this.form.config.content_page) {
        this.$set(this.form.config, 'content_page', {
          "banner_image": "",
          "form_title": this.form.title || "",
          "form_desc": "",
          "content": "",
          "button_text": "送出",
        });
      }

      if (!this.form.config.content_page.form_title) {
        this.form.config.content_page.form_title = this.form.title || "";
      }
    },
    prepareDataForThanksPage() { // NOTE: should be called immediately after this.form is reassigned
      if (!this.form.config.thanks_page) {
        this.$set(this.form.config, 'thanks_page', {
          "banner_image": null,
          "form_title": "感謝您的填寫",
          "form_desc": "",
          "content": "",
          "button_text": "回 LINE 官方帳號 »",
          "button_action": "OA",
          "button_url": "",
        });
      }
    },
    prepareDataForConfirmPage() {
      if (!this.form.config.confirm_page) {
        this.$set(this.form.config, 'confirm_page', {
          "banner_image": null,
          "form_title": "感謝您的填寫",
          "form_desc": "",
          "content": "",
          "confirm_type": null,
        });
      }
    },
    prepareDataForLimitPage() { // NOTE: should be called immediately after this.form is reassigned
      if (!this.form.config.limit_page) {
        this.$set(this.form.config, 'limit_page', {
          "banner_image": null,
          "form_title": "填寫已達上限",
          "form_desc": "",
          "content": "",
          "button_text": "回 LINE 官方帳號 »",
          "button_action": "OA",
          "button_url": "",
        });
      }
    },
    prepareDataForAdvancedSetting() {
      if (!this.form.config.advanced_setting || Array.isArray(this.form.config.advanced_setting)) {
        this.$set(this.form.config, 'advanced_setting', {
          "notify_after_fill_out": false,
          "notify_after_fill_out_template_code": "default_fill_out_form_notify_template",
          "finish_tags": [],
        });
      }
    },
    prepareDataForEventSetting() {
      if (!this.form.config.event_setting) {
        this.$set(this.form.config, 'event_setting', {
          "enable_verify": true,
          "approved_notify": [
            "line",
            "email",
          ],
          "approved_notify_content": "",
          "approved_tags": [],
          "rejected_notify": [
            "line",
            "email",
          ],
          "rejected_notify_content": "",
          "rejected_tags": [],
          "attend_tags": [],
          "onsite_checkin_tags": [],
          "checkin_start_at": null,
          "checkin_end_at": null,
        });
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;
      this.form.start_at = this.formateDatetime(this.start_at);
      this.form.end_at = this.formateDatetime(this.end_at);
      if (this.isEvent) {
        this.form.config.event_setting.checkin_start_at = this.formateDatetime(this.checkinStartAt);
        this.form.config.event_setting.checkin_end_at = this.formateDatetime(this.checkinEndAt);
      }

      try {
        this.showLoading = true;
        // 編輯表單
        if (this.isEditing) {
          await formApi.updateForm(this.form.id, this.form)
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await formApi.createForm({ ...this.form, config: this.form.config });

          console.log(response);
          this.form = response.data.data;
          this.$swal("新增成功", "", "success");
          this.$router.push({ name: 'FormEdit', params: { id: this.form.id } });
          this.isEditing = true;
        }

        this.formChanged = false;

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存表單失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    formateDatetime(datetime) {
      return format(
        set(new Date(datetime.date), {
          hours: Number(datetime.time.HH),
          minutes: Number(datetime.time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    formateDatetimeRevert(datetime) {
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
    async submitWelcomePage() {
      const newImage = this.selectedImages['welcome_banner_image'];

      if (!newImage) {
        this.$set(this.form.config.welcome_page, 'banner_image', null);
      }

      if (newImage && newImage !== this.form.config.welcome_page.banner_image) {
        this.showLoading = true;
        try {
          const imageUrl = await this.uploadByBase64(newImage);
          this.$set(this.form.config.welcome_page, 'banner_image', imageUrl);
        } catch (e) {
          this.showLoading = false;
        }
      }

      await this.submit()
    },
    async submitContentPage() {
      const newImage = this.selectedImages['content_banner_image'];

      if (!newImage) {
        this.$set(this.form.config.content_page, 'banner_image', null);
      }

      if (newImage && newImage !== this.form.config.content_page.banner_image) {
        this.showLoading = true;
        try {
          const imageUrl = await this.uploadByBase64(newImage);
          this.$set(this.form.config.content_page, 'banner_image', imageUrl);
        } catch (e) {
          this.showLoading = false;
        }
      }

      await this.submit()
    },
    async submitAdvancedSetting() {
      await this.submit()
    },
    async submitEventSetting() {
      await this.submit()
    },
    async fetchForm() {
      console.trace()
      if (! this.form.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await formApi.getForm(this.form.id);
        this.form = response.data.data;
        this.form.type = this.form.type || 'survey';
        this.form.terms = this.form.terms || '';
        this.$set(this.selectedImages, 'welcome_banner_image', deepGet(this.form, 'config.welcome_page.banner_image'));
        this.$set(this.selectedImages, 'content_banner_image', deepGet(this.form, 'config.content_page.banner_image'));
        this.$set(this.selectedImages, 'thanks_banner_image', deepGet(this.form, 'config.thanks_page.banner_image'));
        this.$set(this.selectedImages, 'confirm_banner_image', deepGet(this.form, 'config.confirm_page.banner_image'));
        this.$set(this.selectedImages, 'limit_banner_image', deepGet(this.form, 'config.limit_page.banner_image'));
        this.start_at = this.formateDatetimeRevert(this.form.start_at);
        this.end_at = this.formateDatetimeRevert(this.form.end_at);
        this.fillOutSetting = this.form.fill_out_times === null ? 'unlimit' : 'limit';
        if (this.form.config?.event_setting?.checkin_start_at) {
          this.checkinStartAt = this.formateDatetimeRevert(this.form.config.event_setting.checkin_start_at);
          this.checkinEndAt = this.formateDatetimeRevert(this.form.config.event_setting.checkin_end_at);
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveSubjects(newSubjects) {
      this.validSubject = true
      let emptyTitleSubjects = [];
      newSubjects.forEach((subject)=>{
        if(!subject.config.title){
          emptyTitleSubjects.push(subject.order);
          return;
        }
      })
      if(emptyTitleSubjects.length > 0){
        // let errorMessages = emptyTitleSubjects.map((order)=>`題目${order}標題不可為空`).join('</br>');
        // this.$swal("錯誤", errorMessages , "error");
        return;
      }
      try {
        this.showLoading = true;

        const deleteSubjects = async () => {
          const existingSubjects = this.form.subjects;
          const newSubjectIds = newSubjects.map(subject => subject.id);

          if (!existingSubjects || existingSubjects.length === 0) {
            return;
          }

          return Promise.all(
            existingSubjects
              .filter(existingSubject => false == newSubjectIds.includes(existingSubject.id))
              .map(existingSubject => formApi.deleteSubject(this.form.id, existingSubject.id))
          );
        }

        const updateSubjects = async () => {
          return Promise.all(
            newSubjects
              .filter(subject => subject.id)
              .map(subject => {
                const { id: subjectId, type, config, order } = subject;
                return formApi.updateSubject(this.form.id, subjectId, { type, config, order });
              })
          );
        }

        const createSubjects = async () => {
          const subjectsToCreate = newSubjects
            .filter(subject => !subject.id)
            .map(({ type, config, order }) => ({ type, config, order }));

          if (subjectsToCreate.length > 0) {
            await formApi.createSubjects(this.form.id, subjectsToCreate);
          }
        };

        await Promise.all([
          deleteSubjects(),
          updateSubjects(),
          createSubjects(),
        ]);

        await this.fetchForm();

        this.$swal('儲存成功', '', 'success');
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存題目失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}

.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: fit-content;
  height: 7rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.s-cropped-image {
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.radio-button-group-grid {
  display: grid;
  grid-gap: 8px 0;
  grid-template-columns: auto 1fr;
  align-items: center;

  &__option-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
</style>
